import { ContactType } from './../../models/contact-type';
import { Venue } from '../../models/persistency/persistent-models/venue';
import { Component, OnInit, Input } from '@angular/core';
import { Listener } from '../../models/listener';

@Component({
  selector: 'app-venue-contact',
  templateUrl: './venue-contact.component.html',
  styleUrls: ['./venue-contact.component.scss']
})
export class VenueContactComponent extends Listener implements OnInit {
  @Input()
  public showTitle = false;

  @Input()
  public venue!: Venue;

  public location = '';
  public name = '';

  public contactTypes: ContactType[] = [];

  public constructor() {
    super();
  }

  public ngOnInit(): void {
    this.update();
    this.subscribe(this.venue, () => this.update());
  }

  private update(): void {
    this.name = this.venue.getName();

    this.contactTypes = ContactType.All.filter((type) => this.venue.hasContact(type));

    const street = this.venue.getAddress().getStreet();
    const city = this.venue.getAddress().getCity();

    this.location = (street && city)
      ? street + ', ' + city
      : street || city || '';
  }
}
