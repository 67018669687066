import { Router } from '@angular/router';
import { Venue } from '../../models/persistency/persistent-models/venue';
import { Injectable } from '@angular/core';
// import 'autotrack';
// import 'autotrack/lib/plugins/url-change-tracker';
import { Selector } from '../../models/selector';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';

declare let gtag: any;

/**
 * A service responsible for tracking custom events with Google Analytics.
 */
@Injectable({
  providedIn: 'root'
})
export class AnalyticsTrackerService {
  private static tagId = 'G-5RB1TK8SGJ'; // (window.location.hostname.startsWith('test-') ? 'G-S9PSD6KGH9' : 'G-5RB1TK8SGJ'); // of '385971265'; ??? // 'UA-143461633-1';
  public currentSearchQuery = '';
//  private tracker: any;
  private venue?: Venue;


  public constructor(private router: Router, private location: Location) {
    // ga = (window as any)['ga'] ||
    //     function() {
    //      (ga.q = ga.q || []).push(arguments);
    //    };
    // ga.l = +new Date();
    // ga('create', AnalyticsTrackerService.tagId, 'auto');
    // ga((tracker: any) => { this.tracker = tracker; });

    // GA4 implementation
    gtag = (window as any)['gtag']; /* ||
         function() {
          (gtag.q = gtag.q || []).push(arguments);
        };
    gtag('js', new Date());
    gtag(
      'config',
      AnalyticsTrackerService.tagId,
      {
      'custom_map':
        {
          'dimension1': 'venueId',
          'dimension2': 'beerId'
        }
      }
    ); */
  }

  /**
   * Start tracking page views of the given venue.
   */
  public startTrack(venue: Venue): void {
    this.venue = venue;
    const that = this;

    this.location.onUrlChange((url: string, state: any) => {
      console.log('onUrlChange: calling gtag for venue ' + venue.getId() + ' and beer ' + this.getBeerId());
      gtag('event', 'page_view',
      {
        'send_to': AnalyticsTrackerService.tagId,
        'event_category': venue.getUrlPath(),
        'event_label': this.getBeerId(),
        'beerId': this.getBeerId(),
        'venueId': venue.getId(),
        'dimension1': venue.getId(),
        'dimension2': this.getBeerId()
      });  
    });

    /*
    ga('require', 'urlChangeTracker', {
      // This function is called every time the url changes.
      shouldTrackUrlChange: function(newPath: string, oldPath: string): boolean {
        // We track search queries when the query length is maximal. When the user navigates, the query won't get longer,
        // so the length is maximal.
        if (that.currentSearchQuery) {
          that.tracker.send('event', 'search-input', 'search', that.currentSearchQuery);
          that.currentSearchQuery = '';
        }
        that.tracker.set('dimension1', venue.getId());
        that.tracker.set('dimension2', that.getBeerId());
        return !!(newPath && oldPath);
      }
    });
    ga('set', 'dimension1', venue.getId());
    ga('set', 'dimension2', this.getBeerId());
    ga('send', 'pageview');
*/
  }

  private getBeerId(): number | null {
    const split = this.router.url.split('/detail/');
    if (split.length === 2) {
      const id = parseInt(split[1], 10);
      if (!isNaN(id)) {
        return id;
      }
    }
    return null;
  }

  /**
   * Track the current search query.
   */
  public trackSearch(): void {
//    ga('send', 'event', 'search-input', 'search', this.currentSearchQuery);
  console.log('trackSearch: calling gtag for venue ' + this.venue!.getId() + ' and beer ' + this.getBeerId());

    gtag('event', 'search-input',
    {
      'send_to': AnalyticsTrackerService.tagId,
      'search': this.currentSearchQuery,
      'search_term': this.currentSearchQuery,
      'event_category': 'search',
      'event_label': 'search',
      'venueId': this.venue!.getId(),
      'beerId': this.getBeerId(),
    });
  }

  /**
   * Track a characteristics query.
   */
  public trackCharacteristicSearch(aroma: Selector[], color: Selector[], other: Selector[]): void {
    // sort the filters such that every combination of filters is represented in a unique way as a string.
    const filterString = [aroma, color, other]
      .map((selectors) => selectors.map((selector) => selector.getLabelKey()).sort().join('+'))
      .join('|');
//    ga('send', 'event', 'search-characteristic', 'search', filterString);
    console.log('trackCharacteristicSearch: calling gtag for venue ' + this.venue!.getId() + ' and beer ' + this.getBeerId());
    gtag('event', 'search-characteristic',
    {
      'send_to': AnalyticsTrackerService.tagId,
      'search': filterString,
      'search_term': filterString,
      'event_category': 'search',
      'event_label': 'search',
      'venueId': this.venue!.getId(),
      'beerId': this.getBeerId(),
    });
  }

  /**
   * Set the current search query.
   */
  public setCurrentQuery(query: string): void {
    this.currentSearchQuery = query;
  }
}
