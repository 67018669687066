import { SubscribableSubject } from './subscribable';
import { Serializable } from './serializable';
import { Unit } from './unit';
import defaultServingData from './defaults/serving.json';

/**
 * An enum representing the possible casings of a {@link BeerServing}.
 */
export enum Casing {
    Bottle = 'bottle',
    Tap = 'tap',
    Can = 'can'
}
/**
 * A class representing a serve method of a {@link VenueBeer}, which can be subscribed to to listen for changes..
 */
export class BeerServing extends SubscribableSubject<BeerServing> implements Serializable {
    private volume: number;
    private volumeUnit: Unit;
    private casing: Casing;

    private price: number | null;
    private _isInPromotion: boolean;
    private promotionPrice: number | null;

    public constructor(volume: number, volumeUnit: Unit, casing: Casing, price: number | null) {
        super();
        this.volume = volume;
        this.volumeUnit = volumeUnit;
        this.casing = casing;
        this.price = price;

        this._isInPromotion = false;
        this.promotionPrice = null;
    }

    /**
     * Create a beer serving from the given raw data.
     *
     * @param data  The raw data representing a beer serving.
     * @returns A new beer serving with the provided data, or `null` if the provided data is invalid.
     */
    public static read(data: any): BeerServing | null {
        const volume = parseFloat(data.volume);
        const volumeUnit = Unit.getUnitByName(data.volumeUnit);
        const casing = data.casing;
        if (isNaN(volume) || volumeUnit === null || !Object.values(Casing).includes(casing)) {
            return null;
        }
        let price: number | null = parseFloat(data.price);
        if (isNaN(price)) {
            price = null;
        }
        let promoPrice: number | null = parseFloat(data.promotionPrice);
        if (isNaN(promoPrice)) {
            promoPrice = null;
        }
        const serving = new BeerServing(volume, volumeUnit, casing, price);
        serving.setPromotionPrice(promoPrice);
        if (data._isInPromotion) {
            serving.setWhetherIsInPromotion(data._isInPromotion);
        }
        return serving;
    }
    private static isDefaultVolumeName(name: string): name is keyof typeof defaultServingData.volume {
        return name in defaultServingData.volume;
    }
    /**
     * Create a new beer serving with default properties.
     *
     * @param volumeUnit  The volume unit for the new serving.
     * @returns A new beer serving with default properties and the given volume unit.
     */
    public static createDefault(volumeUnit: Unit): BeerServing {
        let volume: number;
        const name = volumeUnit.getName();
        if (BeerServing.isDefaultVolumeName(name)) {
            volume = defaultServingData.volume[name];
        } else {
            console.warn('No default volume found for unit \'' + volumeUnit.getName() + '\'. Defaulting to 33.');
            volume = 33;
        }
        return new BeerServing(
            volume,
            volumeUnit,
            defaultServingData.casing as Casing,
            defaultServingData.price);
    }

    /**
     * Get the volume of this serving.
     *
     * @returns The volume of this serving, provided in the {@link getVolumeUnit | volume unit} of this serving.
     */
    public getVolume(): number {
        return this.volume;
    }
    /**
     * Set the volume of this serving.
     *
     * @param volume  The new volume of this serving, provided in the {@link getVolumeUnit | volume unit} of this serving.
     */
    public setVolume(volume: number): void {
        this.volume = volume;
        this.next(this);
    }
    /**
     * Get the volume unit of this serving.
     *
     * @returns The volume unit of this serving.
     */
    public getVolumeUnit(): Unit {
        return this.volumeUnit;
    }
    /**
     * Set the volume unit of this serving.
     *
     * @param unit  The new volume unit of this serving.
     */
    public setVolumeUnit(unit: Unit): void {
        this.volumeUnit = unit;
        this.next(this);
    }
    /**
     * Get the casing of this serving.
     *
     * @returns The casing of this serving.
     */
    public getCasing(): Casing {
        return this.casing;
    }
    /**
     * Set the casing of this serving.
     *
     * @param casing  The new casing of this serving.
     */
    public setCasing(casing: Casing): void {
        this.casing = casing;
        this.next(this);
    }
    /**
     * Get the price of this serving.
     *
     * @returns The price of this serving, or `null` if none is provided.
     */
    public getPrice(): number | null {
        return this.price;
    }
    /**
     * Set the price of this serving.
     *
     * @param price  The new price of this serving, or `null` if no should be provided.
     */
    public setPrice(price: number | null): void {
        this.price = price;
        this.next(this);
    }
    /**
     * Get whether this serving is in promotion.
     *
     * @returns Whether this serving is in promotion.
     */
    public isInPromotion(): boolean {
        return this._isInPromotion;
    }
    /**
     * Set whether this serving is in promotion.
     *
     * @returns A new value indicating whether this serving is in promotion.
     */
    public setWhetherIsInPromotion(isInPromotion: boolean): void {
        this._isInPromotion = isInPromotion;
        this.next(this);
    }
    /**
     * Get the promotion price of this serving.
     *
     * @returns The promotion price of this serving, or `null` if it has none.
     */
    public getPromotionPrice(): number | null {
        return this.promotionPrice;
    }
    /**
     * Set the promotion price of this serving.
     *
     * @param promotionPrice  The new promotion price of this serving, or `null` if it should have none.
     */
    public setPromotionPrice(promotionPrice: number | null): void {
        this.promotionPrice = promotionPrice;
        this.next(this);
    }

    /**
     * Get the current price of this serving, depending on whether it is in promotion or not.
     *
     * @returns The current price of this serving, depending on whether it is in promotion or not.
     */
    public getCurrentPrice(): number | null {
        if (this.isInPromotion()) {
            return this.getPromotionPrice();
        }
        return this.getPrice();
    }

    /**
     * @inheritDoc
     */
    public toJSON(): any {
        return {
            volume: this.getVolume(),
            volumeUnit: this.getVolumeUnit().toJSON(),
            casing: this.getCasing(),
            price: this.getPrice(),
            _isInPromotion: this.isInPromotion(),
            promotionPrice: this.getPromotionPrice()
        };
    }
}
