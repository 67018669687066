import { Router } from '@angular/router';
import { Injectable, isDevMode, NgZone } from '@angular/core';
import { VenueFacadeService } from '../venue-facade/venue-facade.service';
import { interval } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { VenueService } from '../venue/venue.service';
import { Venue } from '../../models/persistency/persistent-models/venue';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataFreshnessTrackerService {

  private readonly TASK_REFRESH_INTERVAL_MS: number;
  private readonly autoRefresh$: Observable<number>;
  private subscribe: Subscription;

  public constructor(
    private venueFacadeService: VenueFacadeService,
    private venueService: VenueService,
    private platform: Platform
  ) {
    let checkIntervalMinutes: number = (environment.env === 'prod' ? 240 : (environment.env == 'test' ? 60 : /* dev */ 1));
    this.TASK_REFRESH_INTERVAL_MS = checkIntervalMinutes * 60 * 1000; /* 60 secs/minute * 1000 ms/sec */
    console.log("DataFreshnessTrackerService: task refresh interval = " + this.TASK_REFRESH_INTERVAL_MS);

    this.autoRefresh$ = interval(this.TASK_REFRESH_INTERVAL_MS).pipe(
      startWith(0)
    );
    this.subscribe = this.autoRefresh$.subscribe(val => this.checkDataFreshnessAndReloadRawDataIfNecessary(val));
  }

  private checkDataFreshnessAndReloadRawDataIfNecessary(value: number) {
    console.log("DataFreshnessTrackerService: checkDataFreshnessAndReloadRawDataIfNecessary called");
    let venue: Venue | null = this.venueService.getSelectedVenue();
    let venueModifiedPromise: Promise<Boolean> = this.venueFacadeService.checkVenueDataModification(venue);
    let venueBeerModifiedPromise: Promise<Boolean> = this.venueFacadeService.checkVenueBeerDataModification(venue);
    Promise.all([venueModifiedPromise, venueBeerModifiedPromise]).then(resultArray => {
      let venueModified: Boolean = resultArray[0];
      let venueBeerModified: Boolean = resultArray[1];
      console.log("DataFreshnessTrackerService: checkDataFreshnessAndReloadRawDataIfNecessary: venueModified = " + venueModified);
      console.log("DataFreshnessTrackerService: checkDataFreshnessAndReloadRawDataIfNecessary: venueBeerModified = " + venueBeerModified);

      if (venueModified) {
        let venuePromise: Promise<void> = this.venueFacadeService.getRawVenueData(venue, true);
        venuePromise.then((result) => {
          let rawVenueData: any = result;
          this.venueService.reloadVenueFromFacadeRawData(rawVenueData);

          if (venueBeerModified) {
            this.venueService.getSelectedVenue()?.reloadTables();
          }
        })
      }
      else { // venue is not modified, but maybe venueBeer is modified
        if (venueBeerModified) {
          this.venueService.getSelectedVenue()?.reloadTables();
        }
      }
    });
  }
/*
  private checkDataFreshnessAndReloadRawDataIfNecessary_OBSOLETE(value: number) {
    console.log("DataFreshnessTrackerService: checkDataFreshnessAndReloadRawDataIfNecessary called");
    let venuePromise: Promise<any> = this.venueFacadeService.getRawVenueData();
    let venueBeerPromise: Promise<any> = this.venueFacadeService.getRawVenueBeerData();
    Promise.all([venuePromise, venueBeerPromise]).then(resultArray => {
      let rawVenueData: any = resultArray[0];
      let rawVenueBeerData: any = resultArray[1].rows;
      let rawSimilarBeersData = resultArray[1].similarBeers;
      console.log("DataFreshnessTrackerService: checkDataFreshnessAndReloadRawDataIfNecessary: rawVenueData = " + rawVenueData);
      console.log("DataFreshnessTrackerService: checkDataFreshnessAndReloadRawDataIfNecessary: rawVenueBeerData = " + rawVenueBeerData);

      if (rawVenueData !== 0 && rawVenueBeerData !== 0) {
        this.venueService.reloadVenueFromFacadeRawData(rawVenueData);

        this.venueService.getSelectedVenue()?.venueBeerTable.preloadRawData(rawVenueBeerData);
        this.venueService.getSelectedVenue()?.venueBeerTable.activatePreloadedData();
      }
      else {
        console.log("DataFreshnessTrackerService: checkDataFreshnessAndReloadRawDataIfNecessary: no data");
      }
    });
  }
  */
}
