import { BeerResultsComponent } from './components/beer-results/beer-results.component';
import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { VenueContactComponent } from './components/venue-contact/venue-contact.component';
import { VenueAboutComponent } from './components/venue-about/venue-about.component';
import { VenueHoursComponent } from './components/venue-hours/venue-hours.component';
import { SetLanguageComponent } from './components/set-language/set-language.component';
import { VenueBannerComponent } from './components/venue-banner/venue-banner.component';
import { SelectorComponent } from './components/selector/selector.component';
import { BinariesHiveComponent } from './components/binaries-hive/binaries-hive.component';
import { HexagonComponent } from './components/hexagon/hexagon.component';
import { SortAndFilterComponent } from './components/sort-and-filter/sort-and-filter.component';
import { BeerCardComponent } from './components/beer-card/beer-card.component';
import { OrderCardComponent } from './components/order-card/order-card.component';
import { OrderRowComponent } from './components/order-row/order-row.component';
import { HiveComponent } from './components/hive/hive.component';
import { BeerInfoComponent } from './components/beer-info/beer-info.component';
import { AnalysisComponent } from './components/analysis/analysis.component';
import { CollapsableTitleComponent } from './components/collapsable-title/collapsable-title.component';
import { SimilarBeersComponent } from './components/similar-beers/similar-beers.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ErrorComponent } from './components/error/error.component';
import { ShareIconComponent } from './components/share-icon/share-icon.component';
import { ChartComponent } from './components/chart/chart.component';
import { StoreLocatorComponent } from './components/store-locator/store-locator.component';
import { SearchbarWithAutocompleteComponent } from './components/searchbar-with-autocomplete/searchbar-with-autocomplete.component';
import { AdminBeerCardComponent } from './components/admin-beer-card/admin-beer-card.component';
import { TagFilterComponent } from './components/tag-filter/tag-filter.component';
import { EditBeerComponent } from './components/edit-beer/edit-beer.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { CheckboxIconComponent } from './components/checkbox-icon/checkbox-icon.component';
import { LanguageTextComponent } from './components/language-text/language-text.component';
import { PopoverSelectComponent } from './components/popover-select/popover-select.component';
import { ShowResultsBarComponent } from './components/show-results-bar/show-results-bar.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';

import { AdminSettingsGeneralComponent } from './components/admin-settings-general/admin-settings-general.component';
import { AdminSettingsOpeninghoursComponent } from './components/admin-settings-openinghours/admin-settings-openinghours.component';
import { AdminSettingsAboutComponent } from './components/admin-settings-about/admin-settings-about.component';
import { AdminSettingsLangComponent } from './components/admin-settings-lang/admin-settings-lang.component';
import { WishListComponent } from './components/wish-list/wish-list.component';
import { Listener } from './models/listener';
import { PoweredByComponent } from './components/powered-by/powered-by.component';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { ModalImageComponent } from './components/modal-image/modal-image.component';
import { AddBeerComponent } from './components/add-beer/add-beer.component';
import { AddBreweryComponent } from './components/add-brewery/add-brewery.component';
import { SelectBreweryComponent } from './components/select-brewery/select-brewery.component';

@NgModule({
  imports: [CommonModule, IonicModule, MatDatepickerModule, MatMomentDateModule],
  declarations: [
    Listener,
    SearchbarComponent,
    VenueContactComponent,
    VenueAboutComponent,
    VenueHoursComponent,
    SetLanguageComponent,
    VenueBannerComponent,
    SelectorComponent,
    BinariesHiveComponent,
    HexagonComponent,
    SortAndFilterComponent,
    BeerCardComponent,
    OrderCardComponent,
    OrderRowComponent,
    HiveComponent,
    BeerInfoComponent,
    AnalysisComponent,
    CollapsableTitleComponent,
    SimilarBeersComponent,
    LoadingComponent,
    ErrorComponent,
    ShareIconComponent,
    ChartComponent,
    StoreLocatorComponent,
    SearchbarWithAutocompleteComponent,
    AdminBeerCardComponent,
    StoreLocatorComponent,
    TagFilterComponent,
    EditBeerComponent,
    FileInputComponent,
    CheckboxIconComponent,
    LanguageTextComponent,
    PopoverSelectComponent,
    TagFilterComponent,
    ShowResultsBarComponent,
    LoadingOverlayComponent,
    AdminSettingsGeneralComponent,
    BeerResultsComponent,
    AdminSettingsOpeninghoursComponent,
    AdminSettingsAboutComponent,
    AdminSettingsLangComponent,
    WishListComponent,
    PoweredByComponent,
    ModalImageComponent,
    AddBeerComponent,
    AddBreweryComponent,
    SelectBreweryComponent
  ],
  exports: [
    Listener,
    CommonModule,
    SearchbarComponent,
    VenueContactComponent,
    VenueAboutComponent,
    VenueHoursComponent,
    SetLanguageComponent,
    VenueBannerComponent,
    SelectorComponent,
    BinariesHiveComponent,
    HexagonComponent,
    SortAndFilterComponent,
    BeerCardComponent,
    OrderCardComponent,
    OrderRowComponent,
    HiveComponent,
    BeerInfoComponent,
    AnalysisComponent,
    CollapsableTitleComponent,
    SimilarBeersComponent,
    LoadingComponent,
    ErrorComponent,
    ShareIconComponent,
    ChartComponent,
    StoreLocatorComponent,
    SearchbarWithAutocompleteComponent,
    AdminBeerCardComponent,
    StoreLocatorComponent,
    TagFilterComponent,
    EditBeerComponent,
    FileInputComponent,
    CheckboxIconComponent,
    LanguageTextComponent,
    PopoverSelectComponent,
    ShowResultsBarComponent,
    LoadingOverlayComponent,
    AdminSettingsGeneralComponent,
    BeerResultsComponent,
    AdminSettingsGeneralComponent,
    AdminSettingsOpeninghoursComponent,
    AdminSettingsAboutComponent,
    AdminSettingsLangComponent,
    WishListComponent,
    PoweredByComponent,
    MatDatepickerModule,
    MatMomentDateModule,
    ModalImageComponent,
    AddBeerComponent,
    AddBreweryComponent,
    SelectBreweryComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule {}
