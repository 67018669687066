import { PersistentModel } from '../persistent-model';

type SimpleFields<T> = { [K in keyof T]: T[K] extends string | number | (string | number)[] | null ? K : never }[keyof T];

/** An interface describing the base aromas of an analysis. */
export interface BaseAromas {
    malty: number | null;
    sweet: number | null;
    hoppy: number | null;
    bitter: number | null;
    sour: number | null;
    fruity: number | null;
}
export type BaseAromaKey = SimpleFields<BaseAromas>;

const _baseKey = ['malty', 'sweet', 'hoppy', 'bitter', 'sour', 'fruity'];
export function isBaseAromaKey(key: string): key is BaseAromaKey {
    return _baseKey.includes(key);
}

/** An interface representing the binaries of an analysis. */
export interface Binaries {
    malty_malt: number | null;
    malty_bread: number | null;
    malty_toast: number | null;
    malty_biscuit: number | null;
    malty_cereal: number | null;
    malty_wheat: number | null;
    malty_caramel: number | null;
    malty_toffee: number | null;
    malty_brownsugar: number | null;
    malty_chocolate: number | null;
    malty_coffee: number | null;
    malty_roasted: number | null;
    malty_burnt: number | null;
    malty_smoked: number | null;
    malty_peat: number | null;
    malty_tar: number | null;
    malty_ashes: number | null;
    malty_tobacco: number | null;
    fruity_lemon: number | null;
    fruity_lime: number | null;
    fruity_grapefruit: number | null;
    fruity_orange: number | null;
    fruity_pineapple: number | null;
    fruity_passionfruit: number | null;
    fruity_pomegranate: number | null;
    fruity_lychee: number | null;
    fruity_mango: number | null;
    fruity_banana: number | null;
    fruity_greenapple: number | null;
    fruity_redapple: number | null;
    fruity_pear: number | null;
    fruity_peach: number | null;
    fruity_apricot: number | null;
    fruity_grape: number | null;
    fruity_cherry: number | null;
    fruity_wildcherry: number | null;
    fruity_blackcurrant: number | null;
    fruity_raspberry: number | null;
    fruity_blackberry: number | null;
    fruity_strawberry: number | null;
    fruity_blueberry: number | null;
    fruity_plum: number | null;
    fruity_prune: number | null;
    fruity_fig: number | null;
    fruity_date: number | null;
    fruity_raisin: number | null;
    hoppy_fresh: number | null;
    hoppy_old: number | null;
    hoppy_grassy: number | null;
    hoppy_straw: number | null;
    spices_nuts: number | null;
    spices_coconut: number | null;
    spices_almond: number | null;
    spices_nutmeg: number | null;
    spices_pepper: number | null;
    spices_salt: number | null;
    spices_chili: number | null;
    spices_clove: number | null;
    spices_ginger: number | null;
    spices_coriander: number | null;
    spices_juniper: number | null;
    spices_mint: number | null;
    spices_anise: number | null;
    spices_liquorice: number | null;
    spices_eucalyptus: number | null;
    spices_orangezest: number | null;
    spices_lemongrass: number | null;
    spices_cinnamon: number | null;
    spices_vanilla: number | null;
    spices_honey: number | null;
    spices_maplesyrup: number | null;
    spices_tea: number | null;
    spices_floral: number | null;
    spices_elderflower: number | null;
    animal_butter: number | null;
    animal_oldbutter: number | null;
    animal_yoghurt: number | null;
    animal_cheese: number | null;
    animal_yeast: number | null;
    animal_wildyeast: number | null;
    animal_barnyard: number | null;
    animal_wetgoat: number | null;
    animal_leather: number | null;
    animal_oyster: number | null;
    animal_earth: number | null;
    wood_oak: number | null;
    wood_pine: number | null;
    wood_resinous: number | null;
    wood_wine: number | null;
    wood_whiskey: number | null;
    wood_cognac: number | null;
    wood_calvados: number | null;
    wood_port: number | null;
    wood_madeira: number | null;
    wood_sherry: number | null;
    misc_oxidated: number | null;
    misc_paper: number | null;
    misc_metallic: number | null;
    misc_mineral: number | null;
    misc_chalk: number | null;
    misc_glue: number | null;
    misc_solvent: number | null;
    misc_medicinal: number | null;
    misc_skunk: number | null;
    misc_vinegar: number | null;
    misc_soap: number | null;
    misc_cookedcorn: number | null;
    misc_match: number | null;
    misc_egg: number | null;
}
export type BinaryKey = SimpleFields<Binaries>;

/**
 * A {@link PersistentModel} representing an analysis of a beer.
 */
export class Analysis extends PersistentModel {
    public latitude: number | null = null;
    public longitude: number | null = null;
    public origin: string | null = null;
    public sweet: number | null = null;
    public sour: number | null = null;
    public bitter: number | null = null;
    public malty: number | null = null;
    public fruity: number | null = null;
    public hoppy: number | null = null;
    public spicy: number | null = null;
    public intensity: number | null = null;
    public body: number | null = null;
    public astringent: number | null = null;
    public tastes: string | null = null;
    public color: number | null = null;
    public trouble: number | null = null;
    public totalexperience: number | null = null;
    public notes: string | null = null;

    public additionalAromas: string[] = [];

    public readonly binaries: Binaries = {
        malty_malt: null,
        malty_bread: null,
        malty_toast: null,
        malty_biscuit: null,
        malty_cereal: null,
        malty_wheat: null,
        malty_caramel: null,
        malty_toffee: null,
        malty_brownsugar: null,
        malty_chocolate: null,
        malty_coffee: null,
        malty_roasted: null,
        malty_burnt: null,
        malty_smoked: null,
        malty_peat: null,
        malty_tar: null,
        malty_ashes: null,
        malty_tobacco: null,
        fruity_lemon: null,
        fruity_lime: null,
        fruity_grapefruit: null,
        fruity_orange: null,
        fruity_pineapple: null,
        fruity_passionfruit: null,
        fruity_pomegranate: null,
        fruity_lychee: null,
        fruity_mango: null,
        fruity_banana: null,
        fruity_greenapple: null,
        fruity_redapple: null,
        fruity_pear: null,
        fruity_peach: null,
        fruity_apricot: null,
        fruity_grape: null,
        fruity_cherry: null,
        fruity_wildcherry: null,
        fruity_blackcurrant: null,
        fruity_raspberry: null,
        fruity_blackberry: null,
        fruity_strawberry: null,
        fruity_blueberry: null,
        fruity_plum: null,
        fruity_prune: null,
        fruity_fig: null,
        fruity_date: null,
        fruity_raisin: null,
        hoppy_fresh: null,
        hoppy_old: null,
        hoppy_grassy: null,
        hoppy_straw: null,
        spices_nuts: null,
        spices_coconut: null,
        spices_almond: null,
        spices_nutmeg: null,
        spices_pepper: null,
        spices_salt: null,
        spices_chili: null,
        spices_clove: null,
        spices_ginger: null,
        spices_coriander: null,
        spices_juniper: null,
        spices_mint: null,
        spices_anise: null,
        spices_liquorice: null,
        spices_eucalyptus: null,
        spices_orangezest: null,
        spices_lemongrass: null,
        spices_cinnamon: null,
        spices_vanilla: null,
        spices_honey: null,
        spices_maplesyrup: null,
        spices_tea: null,
        spices_floral: null,
        spices_elderflower: null,
        animal_butter: null,
        animal_oldbutter: null,
        animal_yoghurt: null,
        animal_cheese: null,
        animal_yeast: null,
        animal_wildyeast: null,
        animal_barnyard: null,
        animal_wetgoat: null,
        animal_leather: null,
        animal_oyster: null,
        animal_earth: null,
        wood_oak: null,
        wood_pine: null,
        wood_resinous: null,
        wood_wine: null,
        wood_whiskey: null,
        wood_cognac: null,
        wood_calvados: null,
        wood_port: null,
        wood_madeira: null,
        wood_sherry: null,
        misc_oxidated: null,
        misc_paper: null,
        misc_metallic: null,
        misc_mineral: null,
        misc_chalk: null,
        misc_glue: null,
        misc_solvent: null,
        misc_medicinal: null,
        misc_skunk: null,
        misc_vinegar: null,
        misc_soap: null,
        misc_cookedcorn: null,
        misc_match: null,
        misc_egg: null,
    };
    public salty: number | null = null;
    public co2: number | null = null;
    public head: number | null = null;
    public alcohol: number | null = null;
    public carbonation: number | null = null;
    public animal: number | null = null;
    public spices: number | null = null;

    /**
     * Return a list of strings representing the binary aromas that were analysed.
     *
     * @returns The list of strings representing the binary aromas that were analysed. Each `aroma` of these strings
     * can be translated with translation key `'CARD.AROMAS.' + aroma.toUpperCase()`.
     */
    public getAdditionalAromas(): string[] {
        return this.additionalAromas;
    }
    /** Return the {@link BaseAromas} that were analysed. */
    public getBaseAromas(): BaseAromas {
        return {
            malty: this.malty,
            sweet: this.sweet,
            hoppy: this.hoppy,
            bitter: this.bitter,
            sour: this.sour,
            fruity: this.fruity
        };
    }
}
type ModelKey = 'id' | 'creationTime' | 'lastUpdate';
const _modelKey = ['id', 'creationTime', 'lastUpdate'];
export type AnalysisKey = Exclude<SimpleFields<Analysis>, ModelKey>;


const _analysis = new Analysis(null as any);
export function isBinaryKey(key: string): key is BinaryKey {
    return key in _analysis.binaries
        && (_analysis.binaries[key as keyof Binaries] === null || Array.isArray(_analysis.binaries[key as keyof Binaries]));
}
export function isAnalysisKey(key: string): key is AnalysisKey {
    return !_modelKey.includes(key)
        && key in _analysis
        && (_analysis[key as keyof Analysis] === null || Array.isArray(_analysis[key as keyof Analysis]));
}
