import { Beer } from './persistency/persistent-models/beer';
import { VenueBeer } from './persistency/persistent-models/venue-beer';
import { Venue } from './persistency/persistent-models/venue';

/**
 * A class representing a filter for beers and venue beers.
 */
export abstract class Filter {
    public static readonly MatchAll = Filter.CreateFilter((beer) => true);
    public static readonly MatchNone = Filter.CreateFilter((beer) => false);

    /**
     * Create a filter with the given match function.
     */
    public static CreateFilter(match: (beer: Beer, venue: Venue) => boolean): Filter {
        return new class extends Filter {
            public matchBeer(beer: Beer, venue: Venue): boolean {
                return match(beer, venue);
            }
        };
    }

    /**
     * Return whether the given beer matches this filter.
     */
    public abstract matchBeer(beer: Beer, venue: Venue): boolean;

    /**
     * Return whether the given venue beer matches this filter.
     */
    public matchVenueBeer(venueBeer: VenueBeer): boolean {
        return this.matchBeer(venueBeer.getBeer(), venueBeer.getVenue());
    }

    /**
     * Return the given beers that match this filter.
     */
    public filterBeers(beers: Beer[], venue: Venue): Beer[] {
        return beers.filter((beer) => this.matchBeer(beer, venue));
    }
    /**
     * Return the number of given beers that match this filter.
     */
    public numberOfBeers(beers: { [Symbol.iterator](): Iterator<Beer> }, venue: Venue): number {
        let matches = 0;
        for (const beer of beers) {
            if (this.matchBeer(beer, venue)) {
                matches++;
            }
        }
        return matches;
    }
    /**
     * Return this given venue beers that match this filter.
     */
    public filterVenueBeers(venueBeers: VenueBeer[]): VenueBeer[] {
        return venueBeers.filter((venueBeer) => this.matchVenueBeer(venueBeer));
    }
    /**
     * Return the number of given venue beers that match this filter.
     */
    public numberOfVenueBeers(venueBeers: { [Symbol.iterator](): Iterator<VenueBeer> }): number {
        let matches = 0;
        for (const venueBeer of venueBeers) {
            if (this.matchVenueBeer(venueBeer)) {
                matches++;
            }
        }
        return matches;
    }
}
/**
 * A class representing a filter that combines a list of filters with 'AND'.
 */
export class AndFilter<T extends Filter = Filter> extends Filter {
    private filters: Set<T>;
    public constructor(...filters: T[]) {
        super();
        this.filters = new Set<T>(filters);
    }

    /**
     * @inheritDoc
     */
    public matchBeer(beer: Beer, venue: Venue): boolean {
        return Array.from(this.filters).every((f) => f.matchBeer(beer, venue));
    }
    /**
     * @inheritDoc
     */
    public matchVenueBeer(venueBeer: VenueBeer): boolean {
        return Array.from(this.filters).every((f) => f.matchVenueBeer(venueBeer));
    }

    public addFilter(filter: T): void {
        this.filters.add(filter);
    }
    public removeFilter(filter: T): void {
        this.filters.delete(filter);
    }
    public getFilters(): ReadonlySet<T> {
        return this.filters;
    }
    public getSize(): number {
        return this.filters.size;
    }
}
/**
 * A class representing a filter that combines a list of filters with 'OR'.
 */
export class OrFilter<T extends Filter = Filter> extends Filter {
    private filters: Set<T>;
    public constructor(...filters: T[]) {
        super();
        this.filters = new Set<T>(filters);
    }

    /**
     * @inheritDoc
     */
    public matchBeer(beer: Beer, venue: Venue): boolean {
        return Array.from(this.filters).some((f) => f.matchBeer(beer, venue));
    }
    /**
     * @inheritDoc
     */
    public matchVenueBeer(venueBeer: VenueBeer): boolean {
        return Array.from(this.filters).some((f) => f.matchVenueBeer(venueBeer));
    }

    public addFilter(filter: T): void {
        this.filters.add(filter);
    }
    public removeFilter(filter: T): void {
        this.filters.delete(filter);
    }
    public getFilters(): ReadonlySet<T> {
        return this.filters;
    }
    public getSize(): number {
        return this.filters.size;
    }
}
/**
 * A class representing a filter for venue beers. Beers without a venue beer do not match the filter.
 */
export abstract class VenueBeerFilter extends Filter {
    public static CreateVenueBeerFilter(match: (venueBeer: VenueBeer) => boolean): VenueBeerFilter {
        return new class extends VenueBeerFilter {
            public matchVenueBeer(venueBeer: VenueBeer): boolean {
                return match(venueBeer);
            }
        };
    }

    /**
     * @inheritDoc
     */
    public matchBeer(beer: Beer, venue: Venue): boolean {
        const venueBeer = beer.getVenueBeer(venue);
        if (venueBeer) {
            return this.matchVenueBeer(venueBeer);
        }
        return false;
    }

    /**
     * @inheritDoc
     */
    public abstract matchVenueBeer(venueBeer: VenueBeer): boolean;
}
