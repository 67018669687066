import { ModelTable } from '../model-table';
import { Brewery } from './brewery';
import { Cleaner } from '../../cleaner';
import { Address } from '../../address';
import { TranslateService } from '@ngx-translate/core';
import { LoginGuard } from '../../../guards/login/login.guard';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AddBreweryRequest } from '../../data-transfer/add-brewery-request';
import { environment } from '../../../../environments/environment';
import { modelUrls } from '../../../model-urls';
import { VenueService } from '../../../services/venue/venue.service';

/**
 * An abstract {@link ModelTable} of {@link Brewery | Breweries} responsible for reading instances.
 */
export abstract class BreweryTable extends ModelTable<Brewery> {

  constructor(private translateService: TranslateService,
    private loginGuard: LoginGuard,
    private http: HttpClient) {
    super();
  }

  /** @inheritDoc */
  public createInstance(): Brewery {
    return new Brewery(this);
  }
  /** @throws Serialization of breweries is not supported yet */
  public serializeInstance(instance: Brewery): any {
    return {
      id: instance.getId(),
      name: instance.getName(),
      logo: instance.getLogoUrl(),

      // address info
      country: instance.getAddress().getCountry(),
      city: instance.getAddress().getCity(),
      latitude: instance.getAddress().getLatitude(),
      longitude: instance.getAddress().getLongitude(),
      postalCode: instance.getAddress().getPostalCode(),
      region: instance.getAddress().getRegion(),
      street: instance.getAddress().getStreet(),
      streetNumber: instance.getAddress().getStreetNumber(),

      creationTs: instance.getCreationTime(),
      updateTs: instance.getLastUpdate()
    };
  }
  /** @inheritDoc */
  public applyRawData(data: any, instance: Brewery): void {
    instance.setId(Cleaner.parseId(data.id));
    instance.setCreationTime(Cleaner.parseDate(data.creationTs));
    instance.setLastUpdate(Cleaner.parseDate(data.updateTs));
    Cleaner.safeApply(instance, instance.setName, data.name);
    Cleaner.safeApply(instance, instance.setLogoUrl, data.logo);

    const address = instance.getAddress();
    Cleaner.safeApply(address, address.setCity, data.city);
    Cleaner.safeApply(address, address.setCountry, data.country);
    Cleaner.safeApply(address, address.setLatitude, data.latitude);
    Cleaner.safeApply(address, address.setLongitude, data.longitude);
    Cleaner.safeApply(address, address.setRegion, data.region);
    Cleaner.safeApply(address, address.setPostalCode, data.postalCode);
    Cleaner.safeApply(address, address.setStreet, data.street);
    Cleaner.safeApply(address, address.setStreetNumber, data.streetNumber);
  }

    /**
   * Save an instance to the back-end and call super.saveInstance to add this instance to the table (if not present yet).
   *
   * This method should only be called from {@link PersistentModel.save}.
   *
   * @param instance  The instance to save.
   * @returns A promise that resolves when the instance is saved.
   * @throws Saved instance has a null id.
   */
    public saveInstance(instance: Brewery): Promise<void> {

    let userId: string | null = this.loginGuard.getUserToken() != null ? this.loginGuard.getUserToken() : "anonymous";

    let addBreweryRequest = new AddBreweryRequest(
      instance.getName(),
      instance.getAddress().getCountry(),
      instance.getAddress().getCity(),
      userId,
      0.0,
      0.0,
      instance.getTenant(),
      instance.getTenantId()
    );

    const options = { headers: new HttpHeaders({
      'x-api-key': environment['lambdaApiKey'],
      'Content-Type': 'application/json'
    })};

    return this.http.post(environment[modelUrls.beer.base] + modelUrls.brewery.CREATE, addBreweryRequest, options)
      .toPromise().then((response: any) => {

        instance.setId(response.id);
        super.saveInstance(instance);

      }).catch((errorResponse) => {
      console.log(errorResponse);
      throw errorResponse;
    });
  }
  
}
