import { CookieService } from './../cookie/cookie.service';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { LoggerService } from '../logger/logger.service';

/**
 * A service for saving data locally in the browser.
 */
@Injectable({
  providedIn: 'root'
})
export class LocalPersisterService {

  private prefix = 'bh-';

  public constructor(
    private storage: Storage,
    private logger: LoggerService,
    private cookies: CookieService
  ) {
  }


  public set(key: string, value: any): Promise<void> {
    return this.storage.set(this.prefix + key, value);
  }

  public get(key: string): Promise<any> {
    return this.getImpl(key);
  }
  private getImpl(key: string): Promise<any> {
    return this.storage.get(this.prefix + key).then((value) => {
        return value === undefined ? null : value;
      })
      .catch((reason) => {
        this.log('getImpl failed: key = ' + key + ', reason = ' + JSON.stringify(reason));
        return reason;
      });
  }
  private log(s: string): void {
    this.logger.info('local-persister.service', s);
  }

  public setViaCookie(key: string, value: string): void {
    this.cookies.setCookie(key, value);
  }
  public getViaCookie(key: string): string | null {
    return this.cookies.getCookie(key);
  }
  public deleteViaCookie(key: string): void {
    this.cookies.deleteCookie(key);
  }
 }
