import { InitGuard } from './guards/init/init.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

const routes: Routes = [
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminPageModule) },
  { path: ':venue-url', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule), canActivate: [InitGuard] },
  { path: '**', loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundPageModule)}
];
@NgModule({
  imports: [
    IonicModule,
    RouterModule.forRoot(routes, {
      // onSameUrlNavigation: 'reload',
      enableTracing: false,
      relativeLinkResolution: 'corrected'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
