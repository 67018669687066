/**
 * A utility class for cleaning raw data.
 */
export class Cleaner {
    public static parseId(data: any): string | null {
        if (!data || !data.toString()) {
            return null;
        }
        return data.toString();
    }
    public static parseDate(data: any): Date | null {
        if (!data || typeof data !== 'string') {
            return null;
        }
        data = data.replace(' +', '+');
        const dateObj = new Date(data);
        if (isNaN(dateObj.getTime())) {
            return null;
        }
        return dateObj;
    }
    public static parseInt(data:string): number | null {
        if (!data || !data.toString()) {
            return null;
        }
        return parseInt(data);
    }
    public static parseFloat(data:string): number | null {
        if (!data || !data.toString()) {
            return null;
        }
        let dataWithDot = data.replace(",", ".");
        return parseFloat(dataWithDot);
    }
    public static safeApply(instance: any, f: (v: any) => any, data: any): void {
        if (data !== undefined && (typeof data !== 'number' || !isNaN(data))) {
            f.call(instance, data);
        }
    }
    public static safeApply2(instance: any, f: (v: any, w: any) => any, data1: any, data2: any): void {
        if (data1 !== undefined && (typeof data1 !== 'number' || !isNaN(data1)) &&
            data2 !== undefined && (typeof data2 !== 'number' || !isNaN(data2))) {
            f.call(instance, data1, data2);
        }
    }
    public static safeApply3(instance: any, f: (v: any, w: any, x: any) => any, data1: any, data2: any, data3: any): void {
        if (data1 !== undefined && (typeof data1 !== 'number' || !isNaN(data1)) &&
            data2 !== undefined && (typeof data2 !== 'number' || !isNaN(data2)) &&
            data3 !== undefined && (typeof data3 !== 'number' || !isNaN(data3))) {
            f.call(instance, data1, data2, data3);
        }
    }
}
