import { Subscription, NextObserver, PartialObserver } from 'rxjs';

/**
 * An interface representing an object which can be subscribed to.
 *
 * This interface is designed to be compatible with observables of [rxjs](https://rxjs.dev/guide/overview).
 */
export interface Subscribable<T> {
    subscribe(callback?: ((v: T) => void) | PartialObserver<any>): Subscription;
}
/**
 * An abstract class that implements the {@link Subscribable} interface.
 */
export abstract class SubscribableSubject<T> implements Subscribable<T> {
    private subscriptions = new Set<(v: T) => void>();
    public subscribe(callbackOrObserver?: ((v: T) => void) | NextObserver<T>): Subscription {
        if (callbackOrObserver) {
            let callback: (v: T) => void;
            if (typeof callbackOrObserver === 'function') {
                callback = callbackOrObserver;
            } else {
                callback = (v) => callbackOrObserver.next(v);
            }
            this.subscriptions.add(callback);
            return new Subscription(() => this.subscriptions.delete(callback));
        }
        return new Subscription(() => {});
    }
    public next(v: T): void {
        this.subscriptions.forEach((callback) => callback(v));
    }
}
