import { ModelForm } from './model-form';
import { Serializable } from '../serializable';
import { ModelTable } from './model-table';
import { SubscribableSubject } from '../subscribable';
import { BHError } from '../utilities';

/**
 * A class representing a model that persists by synchronizing with the back-end.
 * Changes can be observed by subscribing to the model.
 *
 * When implementing this class, you should call `makeDirty()` each time a field changes
 * to indicate the instance is out-of-sync with the back-end.
 */
export abstract class PersistentModel extends SubscribableSubject<PersistentModel> implements Serializable {
    private _isDirty: boolean;

    private id: string | null;
    private creationTime: Date | null;
    private lastUpdate: Date | null;

    private table: ModelTable<this>;

    /**
     * Create a new persistent model with a given table, and optionally an initial `id`, `creationTime` and `lastUpdate`.
     * @param table The table that collects all synchronized instances of this model.
     * @param id The id of this model. Default is `null`.
     * @param creationTime The time this instance was created. Default is `new Date()`.
     * @param lastUpdate The time this instance changed on the back-end. Default is `null`.
     */
    public constructor(
        table: ModelTable<PersistentModel>,
        id: string | null = null,
        creationTime: Date | null = new Date(),
        lastUpdate: Date | null = null
    ) {
        super();
        this.id = id;
        this.creationTime = creationTime;
        this.lastUpdate = lastUpdate;
        this._isDirty = false;

        this.table = table as unknown as ModelTable<this>;
    }

    /**
     * Get the unique id of this instance.
     * When the instance has not been synchronized yet (and therefore only exists locally), this should be `null`.
     *
     * @returns The id of this instance.
     */
    public getId(): string | null {
        return this.id;
    }
    /**
     * Set the id of this instance.
     *
     * **Warning:** this should only be called in two cases.
     * 1. When reading an instance from the back-end.
     * 2. After saving an new instance to the back-end for the first time.
     * In both cases, this should only be called from the table of this instance.
     *
     * @param id  The id to set the id of this instance to.
     * @throws Cannot change the id of an instance that is already present in its table.
     */
    public setId(id: string | null) {
        if (id !== this.id) {
            if (this.id !== null && this.table.has(this.id)) {
                throw new Error('Cannot change ID of instance that is already present in its table!');
            }
            this.id = id;
            this.makeDirty();
        }
    }
    /**
     * Get the creation time of this instance.
     *
     * @returns The time that this instance was created.
     */
    public getCreationTime(): Date | null {
        return this.creationTime;
    }
    /**
     * Set the creation time of this instance.
     * This should only be called when reading an instance from the back-end.
     *
     * @param date  The time that this instance was created.
     */
    public setCreationTime(date: Date | null): void {
        this.creationTime = date;
        this.makeDirty();
    }
    /**
     * Get the last time this instance was modified on the back-end.
     *
     * @returns The last time this instance was modified on the back-end.
     */
    public getLastUpdate(): Date | null {
        return this.lastUpdate;
    }
    /**
     * Set the last update time of this instance.
     * This should only be called when reading an instance from the back-end, or before saving it to the back-end.
     *
     * @param date  The time that this instance was last modified on the back-end.
     */
    public setLastUpdate(date: Date | null): void {
        this.lastUpdate = date;
        this.makeDirty();
    }

    /**
     * Save this instance to the back-end. After it has been saved, `isDirty()` will equal `false`.
     * If this instance is not dirty, this method returns `Promise.resolve()`.
     *
     * See {@link ModelTable.saveInstance} for more information.
     *
     * @returns A promise that resolves after the instance is saved.
     */
    public save(): Promise<void> {
        if (this.isDirty()) {
            this.lastUpdate = new Date();
            return this.table.saveInstance(this).then(() => { this._isDirty = false; });
        }
        return Promise.resolve();
    }
    /**
     * Delete this instance from the back-end.
     *
     * See {@link ModelTable.deleteInstance} for more information.
     *
     * @returns A promise that resolves after the instance is deleted.
     */
    public delete(): Promise<void> {
        return this.table.deleteInstance(this);
    }
    /**
     * Create a clone of this instance.
     *
     * See {@link ModelTable.clone} for more information.
     *
     * @param instance  The instance to clone.
     * @returns A clone of the given instance.
     */
    public clone(): this {
        return this.table.clone(this);
    }
    /**
     * Create a form of this instance.
     *
     * See {@link ModelTable.createForm} for more information.
     *
     * @returns A form of this instance.
     */
    public createForm(): ModelForm<this> {
        return this.table.createForm(this);
    }

    /**
     * Convert this instance to JSON that will be send to the back-end.
     *
     * See {@link ModelTable.serializeInstance} for more information.
     *
     * @returns A JSON object representing this instance.
     */
    public toJSON(): any {
        return this.table.serializeInstance(this);
    }

    /**
     * Indicate that a property of this instance has changed, so it is out-of-sync with the back-end.
     * All subscriptions to this model will be notified that the model has changed.
     */
    public makeDirty(): void {
        this._isDirty = true;
        this.next(this);
    }
    /**
     * Get whether this instance is out-of-sync with the back-end.
     *
     * @returns `true` if this instance is out-of-sync with the back-end, `false` otherwise.
     */
    public isDirty(): boolean {
        return this._isDirty;
    }
    /**
     * Validates the instance
     * 
     * @returns array of errors, if no errors, then returns an empty array
     */
    public validate(): BHError[] {
        let result: BHError[] = [];
        return result;
    }
    /**
     * Validates the instance
     * 
     * @returns array of errors, if no errors, then returns an empty array
     */
        public isValid(): boolean {
        let result: boolean = this.validate().length === 0;
        return result;
    }
    
 }
