export class AddBreweryRequest {
    constructor(
      public name: String | null,
      public country: String | null,
      public city: String | null,
      public creationUserId: String | null,
      public latitude: Number,
      public longitude: Number,
      public tenantapikey: string | null,
      public tenantId: string | null | undefined
    ) { }
  }
  