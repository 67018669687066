import { Serializable } from './serializable';

/**
 * A class representing a unit which can be used for formatting numbers.
 */
export abstract class Unit implements Serializable {
    private static unitMap = new Map<string, Unit>();
    private static symbolMap = new Map<string, Unit>();

    private name: string;
    private symbol: string;
    private _isPrefix: boolean;
    public constructor(name: string, symbol: string, isPrefix: boolean = false) {
        this.name = name;
        this.symbol = symbol;
        this._isPrefix = isPrefix;
        Unit.unitMap.set(name, this);
        Unit.symbolMap.set(symbol, this);
    }

    public static getUnitByName(name: string): Unit | null {
        return Unit.unitMap.get(name) || null;
    }
    public static getUnitBySymbol(symbol: string): Unit | null {
        return Unit.symbolMap.get(symbol) || null;
    }

    /**
     * @inheritDoc
     */
    public toJSON(): any {
        return this.name;
    }

    /**
     * Format the given number.
     *
     * @param number  The number to format.
     */
    public abstract format(number: number): string;

    /**
     * Get the name of this unit.
     *
     * @returns The name of this unit.
     */
    public getName(): string {
        return this.name;
    }
    /**
     * Get the symbol of this unit.
     *
     * @returns The symbol of this unit.
     */
    public getSymbol(): string {
        return this.symbol;
    }
    /**
     * Get whether this unit is prefix.
     *
     * @returns `true` if this unit is prefix, `false` if this unit is postfix.
     */
    public isPrefix(): boolean {
        return this._isPrefix;
    }
    /**
     * Get whether this unit is postfix.
     *
     * @returns `true` if this unit is postfix, `false` if this unit is prefix.
     */
    public isPostfix(): boolean {
        return !this._isPrefix;
    }
}
abstract class FixedDecimalUnit extends Unit {
    private fractionalDigits: number;
    private decimalSeperator: string;
    public constructor(name: string, symbol: string, fractionalDigits: number, decimalSeperator: string, isPrefix: boolean = false) {
        super(name, symbol, isPrefix);
        this.fractionalDigits = fractionalDigits;
        this.decimalSeperator = decimalSeperator;
    }
    public format(number: number): string {
        return this.formatUnit(number.toFixed(this.fractionalDigits).replace('.', this.decimalSeperator));
    }
    protected abstract formatUnit(formattedNumber: string): string;
}
class PreUnit extends FixedDecimalUnit {
    public constructor(name: string, symbol: string, fractionalDigits: number, decimalSeperator: string) {
        super(name, symbol, fractionalDigits, decimalSeperator, true);
    }
    protected formatUnit(formattedNumber: string): string {
        return `${ this.getSymbol() } ${ formattedNumber }`;
    }
}
class PostUnit extends FixedDecimalUnit {
    public constructor(name: string, symbol: string, fractionalDigits: number, decimalSeperator: string) {
        super(name, symbol, fractionalDigits, decimalSeperator, false);
    }
    protected formatUnit(formattedNumber: string): string {
        return `${ formattedNumber } ${ this.getSymbol() }`;
    }
}

/**
 * A class exposing common currencies.
 */
export class Currencies {
    public static readonly Euro = new PreUnit('Euro', '€', 2, ',');
    public static readonly Dollar = new PreUnit('Dollar', '$', 2, '.');

    public static readonly All = [Currencies.Euro, Currencies.Dollar];
}
/**
 * A class exposing common volume units.
 */
export class Units {
    public static readonly Centiliter = new PostUnit('centiliter', 'cl', 0, ',');
    public static readonly Milliliters = new PostUnit('milliliter', 'ml', 0, ',');

    public static readonly All = [Units.Centiliter, Units.Milliliters];
}
