import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VenueService } from '../../services/venue/venue.service';
import internationalization from '../../models/defaults/internationalization.json';

@Component({
  selector: 'app-set-language',
  templateUrl: './set-language.component.html',
  styleUrls: ['./set-language.component.scss']
})
export class SetLanguageComponent implements OnInit {
  @Input()
  public showTitle = false;

  @Input()
  public hideBackground = false;

  @Input()
  public color = 'var(--bh-menu-text-color)';

  @Input()
  public setLanguage = true;

  @Input()
  public language: string | null = null;

  @Input()
  public languages: string[] = [];

  @Output()
  public languageSelect = new EventEmitter<string>();

  public constructor(
    public translateService: TranslateService,
    private venueService: VenueService
  ) {}

  public ngOnInit(): void {
    if (this.languages.length === 0) {
      this.languages = Array.from(this.venueService.getSelectedVenue(true).getLanguages());
    }
    const allLangs = internationalization.availableLanguages;
    this.languages.sort((a, b) => allLangs.indexOf(a) - allLangs.indexOf(b));

    if (!this.language) {
      this.language = this.translateService.currentLang;
    }
  }

  public onLangSelect(lang: string) {
    if (this.setLanguage) {
      this.language = lang;
      if (lang !== this.translateService.currentLang) {
        this.translateService.use(lang);
      }
    } else {
      this.languageSelect.emit(lang);
    }
  }
}
