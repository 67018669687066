import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { VenueService } from '../venue/venue.service';

/**
 * A utility service for common navigation tasks.
 */
@Injectable({
  providedIn: 'root'
})
export class UrlService {
  public constructor(
    private router: Router,
    private venueService: VenueService
  ) {
  }

  public getFullUrl(): string {
    return environment.baseUrl + window.location.pathname;
  }

  public navigateToProfilePage(): void {
    const profileUrl = environment.backendBaseUrl + 'myProfile'; // + '?returnUrl=' + escape(this.getFullUrl());
    window.location.href = profileUrl;
  }

  public navigateToAdminPage(page: string): Promise<boolean> {
    return this.router.navigateByUrl('/admin/' + page);
  }
  private buildQueryParams(parameters: string[] = []): string {
    let queryParams = '';
    if (parameters && parameters.length > 1) {
      queryParams = '?';
      for (let i = 0; i < parameters.length; i += 2) {
        queryParams = queryParams + escape(parameters[i]) + '=' + escape(parameters[i + 1]);
      }
    }
    return queryParams;
  }

  public openExternalPage(url: string, parameters: string[] = []): void {
    // only navigate to absolute http or https urls
    const queryParams = this.buildQueryParams(parameters);
    const win = window.open(
      url + queryParams,
      '_blank'
    );
    win?.focus();
  }

  public getAdminPage(): string | null {
    const urlSegments = this.router.url.split('admin/');
    return urlSegments.length > 1 ? urlSegments[1] : null;
  }

  public openPreview(): void {
    const win = window.open(
      this.venueService.getSelectedVenue(true).getUrlPath(),
      '_blank'
    );
    win?.focus();
  }

  public isKioskMode(): boolean {
    return window.location.host.includes('kiosk.');
  }

}
