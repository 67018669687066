<div
  [ngClass]="'set-language-wrapper' + (hideBackground ? '' : ' background')"
  [ngStyle]="{ color: color }"
>
  <div *ngIf="showTitle" class="title">
    {{ translateService.instant("MENU.LANGUAGES") }}
  </div>
  <div class="languages">
    <span *ngFor="let lang of languages">
      <span
        [class]="'langspan' + (lang === language ? ' selectedLang' : '')"
        (click)="onLangSelect(lang)"
      >
        {{ lang.toUpperCase() }}
      </span>
      {{ languages.indexOf(lang) == languages.length - 1 ? "" : " - " }}
    </span>
  </div>
</div>
