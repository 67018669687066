import { SubscribableSubject } from './subscribable';

/**
 * A class representing a physical address, which can be subscribed to to listen for changes.
 */
export class ElectronicShelfLabelConfig extends SubscribableSubject<ElectronicShelfLabelConfig> {
    private hasElectronicShelfLabelIntegration: boolean = false;
    private color: string = "WHITE";
    private duration: number = 120;
    private pattern: string = "EACH_1_SECOND";

    public static colors = [ "WHITE" ];
    public static durations = [ 5, 10, 30, 60, 90, 120 ];
    public static patterns = [ "FLASH_4_TIMES","EACH_1_SECOND","EACH_2_SECONDS","EACH_4_SECONDS" ];

    public ElectronicShelfLabelConfig() {
        this.hasElectronicShelfLabelIntegration = false;
        this.color = ElectronicShelfLabelConfig.colors[0];
        this.duration = ElectronicShelfLabelConfig.durations[5];
        this.pattern = ElectronicShelfLabelConfig.patterns[1];
        //console.log("ESL: " + "constructed");
    }
    /**
     * Create a new ElectronicShelfLabelConfig from the given raw data.
     *
     * @param  data The raw data representing an ElectronicShelfLabelConfig.
     */
     public static read(data: any): ElectronicShelfLabelConfig {
        const instance = Object.assign(new ElectronicShelfLabelConfig(), data);
        //console.log("ESL: " + "read: " + instance);
        return instance;
    }
    /**
     * Get the indicator if this venue has electronic shelf labeling.
     *
     * @returns The the indicator if this venue has electronic shelf labeling.
     */
     public getHasElectronicShelfLabelIntegration(): boolean {
        //console.log("ESL: " + "getHasElectronicShelfLabelIntegration: " + this.hasElectronicShelfLabelIntegration);
        return this.hasElectronicShelfLabelIntegration;
    }
    /**
     * Set the the indicator if this venue has electronic shelf labeling.
     *
     * @param hasElectronicShelfLabelIntegration the indicator if this venue has electronic shelf labeling.
     */
    public setHasElectronicShelfLabelIntegration(hasElectronicShelfLabelIntegration: boolean): void {
        this.hasElectronicShelfLabelIntegration = hasElectronicShelfLabelIntegration;
        this.next(this);
        //console.log("ESL: " + "getHasElectronicShelfLabelIntegration: " + this.hasElectronicShelfLabelIntegration);
    }
    /**
     * Get the color of this electronic shelf label config.
     *
     * @returns The color of the LED to light up, or null if not known.
     */
     public getColor(): string | null {
        //console.log("ESL: " + "getColor: " + this.color);
        return this.color;
    }
    /**
     * Set the color of this electronic shelf label config.
     *
     * @param color  The color of the LED to light up, or null if not known.
     */
    public setColor(color: string): void {
        this.color = color;
        this.next(this);
        //console.log("ESL: " + "setColor: " + this.color);
    }
    /**
     * Get the pattern of this electronic shelf label config.
     * Possible patterns are: "FLASH_4_TIMES","EACH_1_SECOND","EACH_2_SECONDS","EACH_4_SECONDS"
     *
     * @returns The pattern of the LED to light up, or null if not known. Possible patterns are :"FLASH_4_TIMES","EACH_1_SECOND","EACH_2_SECONDS","EACH_4_SECONDS"
     */
     public getPattern(): string | null {
        //console.log("ESL: " + "getPattern: " + this.pattern);
        return this.pattern;
    }
    /**
     * Set the pattern of this electronic shelf label config.
     * Possible patterns are: "FLASH_4_TIMES","EACH_1_SECOND","EACH_2_SECONDS","EACH_4_SECONDS"
     *
     * @param pattern  The pattern of the LED to light up, or null if not known. Possible patterns are :"FLASH_4_TIMES","EACH_1_SECOND","EACH_2_SECONDS","EACH_4_SECONDS"
     */
    public setPattern(pattern: string): void {
        this.pattern = pattern;
        this.next(this);
        //console.log("ESL: " + "setPattern: " + this.pattern);
    }
    /**
     * Get the duration in seconds of this electronic shelf label config.
     *
     * @returns The duration in seconds of the LED to light up, or null if not known.
     */
     public getDuration(): number {
        //console.log("ESL: " + "getDuration: " + this.duration);
        return this.duration;
    }
    /**
     * Set the duration in seconds of this electronic shelf label config.
     *
     * @param duration  The duration in seconds of the LED to light up, or null if not known.
     */
    public setDuration(duration: number): void {
        this.duration = duration;
        this.next(this);
        //console.log("ESL: " + "setDuration: " + this.duration);
    }

    public getColors(): string[] {
        return ElectronicShelfLabelConfig.colors;
    }
    public getDurations(): number[] {
        return ElectronicShelfLabelConfig.durations;
    }
    public getPatterns(): string[] {
        return ElectronicShelfLabelConfig.patterns;
    }
}