import { PersistentModel } from '../persistent-model';
import { LanguageMap } from '../../language-map';
import { ModelTable } from '../model-table';
import { Venue } from './venue';

/**
 * An enum representing the possible types of {@link BeerTag}s.
 */
export enum BeerTagType {
    Food = 'food',
    Other = 'other'
}
/**
 * A {@link PersistentModel} representing a tag for {@link VenueBeer}s.
 */
export class BeerTag extends PersistentModel {
    private type: BeerTagType;
    private readonly labels: LanguageMap;
    /**
     * Create a new beer-tag with type {@link BeerTagType.Other}.
     *
     * @param venue  The venue that defines the default language.
     * @param table  The table that this tag belongs to.
     * @param id  The id of the new instance. Defaults to `null`.
     */
    public constructor(venue: Venue, table: ModelTable<BeerTag>, id: string | null = null) {
        super(table, id);
        this.type = BeerTagType.Other;
        this.labels = new LanguageMap(() => venue.getDefaultLanguage());
        this.labels.subscribe(() => this.makeDirty());
    }

    /**
     * Get the type of this beer-tag.
     *
     * @returns The type of this beer-tag.
     */
    public getType(): BeerTagType {
        return this.type;
    }
    /**
     * Set the type of this beer-tag.
     *
     * @param type  The type to set this beer-tag to.
     */
    public setType(type: BeerTagType): void {
        this.type = type;
        this.makeDirty();
    }
    /**
     * Get the label map that defines the label of this beer-tag in different languages.
     *
     * @returns A {@link LanguageMap} representing the label of this beer-tag in different languages.
     */
    public getLabelMap(): LanguageMap {
        return this.labels;
    }
    /**
     * Get the label of this beer-tag in the given language, or in the default language if not present,
     * or `null` if no label in the default language is present.
     *
     * @param language  The preferred language for the label.
     */
    public getLabel(language: string): string | null {
        return this.labels.get(language) || null;
    }
    /**
     * Get the label of this beer-tag in the given language, or in the default language if not present,
     * or any other language if no label in the default language is present.
     *
     * @param language  The preferred language for the label.
     */
    public getAnyLabel(language: string): string | null {
        return this.labels.getAny(language) || null;
    }
    /**
     * Set the label of this beer-tag for a given language.
     *
     * @param language  The language of the given label.
     * @param value  The label.
     */
    public setLabel(language: string, value: string): void {
        this.labels.set(language, value);
    }
    /**
     * Remove the label of this beer-tag in the given language.
     *
     * @param language  The language of which label should be removed.
     */
    public removeLabel(language: string): void {
        this.labels.delete(language);
    }

    public equals(other: BeerTag): Boolean {
        return (this.getId() == other.getId());
    }
}
