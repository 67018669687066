import { Subscription } from 'rxjs';
import { Weekday } from './../../weekday';
import { BeerTagTable } from './beer-tag-table';
import { LoginGuard } from '../../../guards/login/login.guard';
import { HttpClient } from '@angular/common/http';
import { VenueBeerTable } from './venue-beer-table';
import { OpeningDay, OpeningTimes } from '../../opening-time';
import { ContactType, ContactTypeMap } from '../../contact-type';
import { RefdataService } from '../../../services/refdata/refdata.service';
import { TranslateService } from '@ngx-translate/core';
import { OtherSelectors, Hexaselector } from '../../selector';
import { VenueBeer } from './venue-beer';
import { LanguageMap } from '../../language-map';
import { Unit } from '../../unit';
import { PersistentModel } from '../persistent-model';
import { Address } from '../../address';
import { ModelTable } from '../model-table';
import { Beer } from './beer';
import { BeerTag } from './beer-tag';
import defaults from '../../defaults/venue.json';
import colors from '../../defaults/colors.json';
import internationalization from '../../defaults/internationalization.json';
import { VenueBeerSet } from '../../venue-beer-set';
import { VenueFacadeService } from '../../../services/venue-facade/venue-facade.service';
import { ElectronicShelfLabelConfig } from '../../electronic-shelf-label-config';

/**
 * A {@link PersistentModel} representing a venue.
 */
export class Venue extends PersistentModel {
    // info
    private name: string;
    private abouts: LanguageMap;
    private maxLowABV: number;
    private currency: Unit;
    private urlPath: string;
    private address: Address;
    private contact: ContactTypeMap;
    private defaultLanguage: string;
    private languages: Set<string>;
    private defaultVolumeUnit: Unit;
    private openingTimes: OpeningTimes;

    // personalization
    private pricesShouldBeShown: boolean;
    private welcomePageShouldBeShown: boolean;
    private otherSelectors: Hexaselector;
    private bannerUrl: string | null;
    private logoUrl: string | null;
    private welcomeImageUrl: string | null;
    private welcomeImageUrlMobile: string | null;
    private promoImageUrls: string[];
    private promoRotationTime: number; // in seconds
    private floorPlanUrl: string | null;
    private autoHomeTime: number | null; // in seconds, or null if there should be no auto home timer.
    private primaryColor: string;
    private secondaryColor: string;
    private menuTextColor: string;

    // electronic shelf label integration
    private electronicShelfLabelConfig: ElectronicShelfLabelConfig;

    private ownerUserId: string | null;

    private similarVenueBeersMap: Map<Beer, Set<VenueBeer>>;

    // must show Beerhive download
    public mustShowBeerhiveDownloadValue: boolean = false;

    /**
     * The table containg all {@link VenueBeer}s of this venue.
     */
    public venueBeerTable: VenueBeerTable;
    /**
     * The table containing all {@link BeerTag}s of this venue.
     */
    public beerTagTable: BeerTagTable;


    public constructor(table: ModelTable<Venue>, otherSelectors: OtherSelectors, private http: HttpClient, private refData: RefdataService,
        private beerTable: ModelTable<Beer>, private translateService: TranslateService, private loginGuard: LoginGuard,
        private venueFacadeService: VenueFacadeService,
        id: string | null = null, creationTime: Date | null = new Date(), lastUpdate: Date | null = null,
        venueBeerTable: VenueBeerTable | null = null, beerTagTable: BeerTagTable | null = null,
        electronicShelfLabelConfig: ElectronicShelfLabelConfig | null = null) {
        super(
            table, id, creationTime, lastUpdate
        );
        this.beerTagTable = beerTagTable ||
            new BeerTagTable(this);
        this.venueBeerTable = venueBeerTable ||
            new VenueBeerTable(this, this.beerTagTable, http, refData, beerTable, translateService, loginGuard, this.venueFacadeService);
        this.ownerUserId = null;
        this.address = new Address();
        this.contact = new ContactTypeMap();
        this.openingTimes = new OpeningTimes();
        this.openingTimes.subscribe(() => this.makeDirty());
        this.abouts = new LanguageMap(() => this.getDefaultLanguage());
        this.abouts.subscribe(() => this.makeDirty());
        this.similarVenueBeersMap = new Map<Beer, Set<VenueBeer>>();
        this.electronicShelfLabelConfig = electronicShelfLabelConfig != null ? electronicShelfLabelConfig : new ElectronicShelfLabelConfig();
        this.electronicShelfLabelConfig.subscribe(() => this.makeDirty());

        // Set default values
        this.name = defaults.name;
        this.maxLowABV = defaults.maxLowABV;
        this.currency = Unit.getUnitByName(defaults.currency)!;
        this.address.setCountry(defaults.country);
        this.address.subscribe(() => this.makeDirty());
        this.defaultLanguage = defaults.defaultLanguage;
        this.languages = new Set<string>(internationalization.availableLanguages);
        this.defaultVolumeUnit = Unit.getUnitByName(defaults.defaultVolumeUnit)!;

        this.pricesShouldBeShown = defaults.pricesShouldBeShown;
        this.welcomePageShouldBeShown = defaults.welcomePageShouldBeShown;
        this.otherSelectors = defaults.otherSelectors
            .map((key) => otherSelectors.getSelectorByLabelKey(key)) as unknown as Hexaselector;

        this.autoHomeTime = defaults.autoHomeTime;
        this.primaryColor = colors.primaryColor;
        this.secondaryColor = colors.secondaryColor;
        this.menuTextColor = colors.menuTextColor;

        this.urlPath = '';
        this.bannerUrl = null;
        this.logoUrl = null;
        this.welcomeImageUrl = null;
        this.welcomeImageUrlMobile = null;
        this.promoImageUrls = [];
        this.promoRotationTime = defaults.promoRotationTime;
        this.floorPlanUrl = null;
    }


    /**
     * Load the {@link VenueBeer} table and {@link BeerTag} table of this venue.
     *
     * @returns A promise that resolves after the tables are loaded.
     */
    public loadTables(): Promise<void> {
        return ModelTable.loadTables(this.venueBeerTable, this.beerTagTable);
    }

    /**
     * Reoad the {@link VenueBeer} table and {@link BeerTag} table of this venue.
     *
     * @returns A promise that resolves after the tables are loaded.
     */
    public reloadTables(): void {
        let reloadedBeerTagTable: BeerTagTable = this.beerTagTable.cloneTable();
        let reloadedVenueBeerTable: VenueBeerTable =
            new VenueBeerTable(this, reloadedBeerTagTable, this.http, this.refData, this.beerTable, this.translateService, this.loginGuard, this.venueFacadeService);
        ModelTable.loadTables(reloadedVenueBeerTable, reloadedBeerTagTable).then(() => {
            this.venueBeerTable = reloadedVenueBeerTable;
            this.beerTagTable = reloadedBeerTagTable;
        });
    }

    /**
     * Returns whether this venue contains the minimal information to be valid.
     *
     * @returns Whether this venue contains the minimal information to be valid.
     */
    public isValid(): boolean {
        if (!this.getName()) {
            return false;
        }
        if (!this.getUrlPath()) {
            return false;
        }
        if (!this.getAddress().getStreet()) {
            return false;
        }
        if (!this.getAddress().getCity()) {
            return false;
        }
        return true;
    }
    /**
     * Return the set of {@link VenueBeer}s of this venue.
     */
    public getVenueBeers(): ReadonlySet<VenueBeer> {
        return this.venueBeerTable.getInstances();
    }

    /**
     * Get the name of this venue.
     *
     * @returns The name of this venue.
     */
    public getName(): string {
        return this.name;
    }
    /**
     * Set the name of this venue.
     *
     * @param name  The new name for this venue.
     */
    public setName(name: string): void {
        this.name = name;
        this.makeDirty();
    }
    /**
     * Get a language map representing a description of this venue.
     *
     * @returns A language map representing a description of this venue.
     */
    public getAbouts(): LanguageMap {
        return this.abouts;
    }
    /**
     * Get the description of this venue in the specified language, or in the current language if none given.
     *
     * @param language  The language to get the description in. Defaults to the current language of the translate service.
     * @returns A description of this venue in the specified language.
     */
    public getAbout(language: string = this.translateService.currentLang): string | null {
        return this.abouts.get(language) || null;
    }
    /**
     * Set a description of this venue for a specific language.
     *
     * @param language  The language the description is written in.
     * @param about  The new description of this venue for the specified language.
     */
    public setAbout(language: string, about: string): void {
        this.abouts.set(language, about);
    }
    /**
     * Remove a description of this venue for the specified language.
     *
     * @param language  The language for which the description should be removed.
     */
    public removeAbout(language: string): void {
        this.abouts.delete(language);
    }
    /**
     * Get the maximum ABV (alcohol by volume) percentage that should be considered a low percentage. A beer
     * with a higher ABV is considered strong.
     *
     * @returns The maximum ABV for which a beer is not considered strong, in percent.
     */
    public getMaxLowABV(): number {
        return this.maxLowABV;
    }
    /**
     * Set the maximum ABV (alcohol by volume) percentage that should be considered a low percentage. A beer
     * with a higher ABV is considered strong.
     *
     * @param percentage  The new maximum ABV for which a beer is not considered strong, in percent.
     */
    public setMaxLowABV(percentage: number): void {
        this.maxLowABV = percentage;
        this.makeDirty();
    }
    /**
     * Get the currency that is used by this venue.
     *
     * @returns The currency that is used by this venue.
     */
    public getCurrency(): Unit {
        return this.currency;
    }
    /**
     * Set the currency used by this venue.
     *
     * @param currency  The new currency for this venue.
     */
    public setCurrency(currency: Unit): void {
        this.currency = currency;
        this.makeDirty();
    }
    /**
     * Get the path of the url where this venue resides on.
     *
     * @returns The path of the url where this venue resides on.
     */
    public getUrlPath(): string {
        return this.urlPath;
    }
    /**
     * Set the path of the url where this venue resides on.
     *
     * @param urlPath  The new path of the url for this venue.
     */
    public setUrlPath(urlPath: string): void {
        this.urlPath = urlPath;
        this.makeDirty();
    }
    /**
     * Get the address of this venue.
     *
     * @returns The (mutable) address of this venue.
     */
    public getAddress(): Address {
        return this.address;
    }
    /**
     * Get a contact address for contacting the owners of this venue.
     *
     * @param type  The type of contact.
     * @returns A contact address of the specified type.
     */
    public getContact(type: ContactType): string | null {
        return this.contact.get(type) || null;
    }
    /**
     * Set a contact address for contacting the owners of this venue.
     *
     * @param type  The type of contact.
     * @param contact  The new contact address of the specified type.
     */
    public setContact(type: ContactType, contact: string | null): void {
        this.contact.set(type, contact);
        this.makeDirty();
    }
    /**
     * Remove a contact address for contacting the owners of this venue.
     *
     * @param type  The type of contact to remove.
     */
    public removeContact(type: ContactType): void {
        this.contact.delete(type);
        this.makeDirty();
    }
    /**
     * Return whether the owners of this venue can be contacted with a specified contact type.
     *
     * @param type  The contact type to check.
     * @returns Whether the owners of this venue can be contacted with the specified contact type.
     */
    public hasContact(type: ContactType): boolean {
        return this.contact.has(type);
    }
    /**
     * Return whether the owners of this venue can be contacted in some way.
     *
     * @returns Whether the owners of this venue can be contacted in some way.
     */
    public hasAnyContact(): boolean {
        return this.contact.size > 0;
    }
    /**
     * Get the default language of this venue.
     *
     * @returns The default language of this venue.
     */
    public getDefaultLanguage(): string {
        return this.defaultLanguage;
    }
    /**
     * Set the default language of this venue.
     *
     * @param language  The new default language of this venue.
     * @throws The default language must be one of the available languages of this venue.
     */
    public setDefaultLanguage(language: string): void {
        if (!this.getLanguages().has(language)) {
            throw new Error('The default language must be one of the available languages of this venue.');
        }
        this.defaultLanguage = language;
        this.makeDirty();
    }
    /**
     * Get the available languages of this venue.
     *
     * @returns A set containing all available languages of this venue.
     * @Suggestion Save available languages as an array instead of a set, because order matters.
     */
    public getLanguages(): ReadonlySet<string> {
        return this.languages;
    }
    /**
     * Add a language to the available languages of this venue.
     *
     * @param language  The language to add to the available languages of this venue.
     */
    public addLanguage(language: string): void {
        this.languages.add(language);
        this.makeDirty();
    }
    /**
     * Remove a language from the available languages of this venue.
     *
     * @param language  The language to remove from the available languages of this venue.
     * @throws Cannot remove the default language from the available languages of this venue.
     */
    public removeLanguage(language: string): void {
        if (this.defaultLanguage === language) {
            throw new Error('Cannot remove the default language from the available languages of this venue.');
        }
        this.languages.delete(language);
        this.makeDirty();
    }
    /**
     * Get the default volume unit of this venue.
     *
     * @returns The default volume unit of this venue.
     */
    public getDefaultVolumeUnit(): Unit {
        return this.defaultVolumeUnit;
    }
    /**
     * Set the default volume unit of this venue.
     *
     * @param unit  The new default volume unit for this venue.
     */
    public setDefaultVolumeUnit(unit: Unit) {
        this.defaultVolumeUnit = unit;
        this.makeDirty();
    }
    /**
     * Get the opening times at a specified day.
     *
     * @param day  The day to get the opening times at.
     */
    public getOpeningTimesAt(day: Weekday): OpeningDay {
        return this.openingTimes.get(day);
    }
    /**
     * Get the opening times of this venue.
     *
     * @returns The opening times of this venue.
     */
    public getOpeningTimes(): OpeningTimes {
        return this.openingTimes;
    }
    /**
     * Return whether this venue has opening times.
     *
     * @return Whether this venue has opening times.
     */
    public hasOpeningTimes(): boolean {
        for (const openingDay of this.openingTimes.values()) {
            if (openingDay.isOpen() && openingDay.times.length > 0) {
                return true;
            }
        }
        return false;
    }

    /**
     * Get the {@link BeerTag}s defined in this venue.
     *
     * @returns A set containing all defined {@link BeerTag}s of this venue.
     */
    public getBeerTags(): ReadonlySet<BeerTag> {
        return this.beerTagTable.getInstances();
    }
    /**
     * Get all {@link BeerTag}s of this venue that have a label in the specified language.
     *
     * @param language  The language to filter the tags on.
     * @returns A set containing all {@link BeerTag}s with a label in the specified language.
     */
    public getBeerTagsForLanguage(language: string): ReadonlySet<BeerTag> {
        return this.beerTagTable.findAll((tag) => tag.getLabel(language) !== null);
    }
    /**
     * Check whether there are {@link BeerTag}s of this venue that have a label in the specified language.
     *
     * @param language  The language to filter the tags on.
     * @returns Whether there are {@link BeerTag}s of this venue that have a label in the specified language.
     */
    public hasBeerTagsForLanguage(language: string): boolean {
        return this.beerTagTable.findAll((tag) => tag.getLabel(language) !== null).size > 0;
    }

    /**
     * Return whether prices of venue beers should be shown to the user.
     *
     * @returns Whether prices of venue beers should be shown to the user.
     */
    public shouldPricesBeShown(): boolean {
        return this.pricesShouldBeShown;
    }
    /**
     * Set whether prices of venue beers should be shown to the user.
     *
     * @param shouldBeShown  The new value indicating whether prices of venue beers should be shown to the user.
     */
    public setWhetherPricesShouldBeShown(shouldBeShown: boolean): void {
        this.pricesShouldBeShown = shouldBeShown;
        this.makeDirty();
    }
    /**
     * Return whether a welcome page should be shown when a user navigates to this venue.
     *
     * @returns Whether a welcome page should be shown when a user navigates to this venue.
     */
    public shouldWelcomePageBeShown(): boolean {
        return this.welcomePageShouldBeShown;
    }
    /**
     * Set whether a welcome page should be shown when a user navigates to this venue.
     *
     * @param shouldBeShown  The new value indicating whether a welcome page should be shown when a user navigates to this venue.
     */
    public setWhetherWelcomePageShouldBeShown(shouldBeShown: boolean): void {
        this.welcomePageShouldBeShown = shouldBeShown;
        this.makeDirty();
    }
    /**
     * Get the {@link Hexaselector} containing custom selectors to filter beers with.
     *
     * @returns The {@link Hexaselector} containing custom selectors to filter beers with.
     */
    public getOtherSelectors(): Hexaselector {
        return this.otherSelectors;
    }
    /**
     * Set the {@link Hexaselector} containing custom selectors to filter beers with.
     *
     * @param hexaselector The new {@link Hexaselector} containing custom selectors to filter beers with.
     */
    public setOtherSelectors(hexaselector: Hexaselector): void {
        this.otherSelectors = hexaselector;
        this.makeDirty();
    }
    /**
     * Get the path to the banner image of this venue.
     *
     * @return The path to the banner image of this venue.
     */
    public getBannerUrl(): string | null {
        return this.bannerUrl;
    }
    /**
     * Set the path to the banner image of this venue.
     *
     * @param url The new path for the banner image of this venue.
     */
    public setBannerUrl(url: string | null): void {
        this.bannerUrl = url;
        this.makeDirty();
    }
    /**
     * Get the path to the logo image of this venue.
     *
     * @return The path to the logo image of this venue.
     */
    public getLogoUrl(): string | null {
        return this.logoUrl;
    }
    /**
     * Set the path to the logo image of this venue.
     *
     * @param url The new path for the logo image of this venue.
     */
    public setLogoUrl(url: string | null): void {
        this.logoUrl = url;
        this.makeDirty();
    }
    /**
     * Get the path to the background image of the widescreen welcome page.
     *
     * @return The path to the background image of the widescreen welcome page.
     */
    public getWelcomeImageUrl(): string | null {
        return this.welcomeImageUrl;
    }
    /**
     * Set the path to the background image of the widescreen welcome page.
     *
     * @param url  The new path for the background image of the widescreen welcome page.
     */
    public setWelcomeImageUrl(url: string | null): void {
        this.welcomeImageUrl = url;
        this.makeDirty();
    }
    /**
     * Get the path to the background image of the mobile welcome page.
     *
     * @return The path to the background image of the mobile welcome page.
     */
    public getWelcomeImageUrlMobile(): string | null {
        return this.welcomeImageUrlMobile;
    }
    /**
     * Set the path to the background image of the mobile welcome page.
     *
     * @param url  The new path for the background image of the mobile welcome page.
     */
    public setWelcomeImageUrlMobile(url: string | null): void {
        this.welcomeImageUrlMobile = url;
        this.makeDirty();
    }
    /**
     * Get the path to the background image of the widescreen welcome page,
     * or the path to a fallback image if none is specified for this venue.
     *
     * @return The path to the background image of the welcome page, or the path to a fallback image if none is specified for this venue.
     */
    public getWelcomeImageUrlWithFallback(): string {
        return this.getWelcomeImageUrl() || 'assets/backgrounds/welcome_beer.jpg';
    }
    /**
     * Get the path to the background image of the mobile welcome page,
     * or the path to a fallback image if none is specified for this venue.
     *
     * @return The path to the background image of the welcome page, or the path to a fallback image if none is specified for this venue.
     */
    public getWelcomeImageUrlMobileWithFallback(): string {
        return this.getWelcomeImageUrlMobile() || 'assets/backgrounds/welcome_beer.jpg';
    }
    /**
     * Get the path to the promo images at zero-based index of the widescreen welcome page.
     *
     * @return The path to the promo images at zero-based index of the widescreen welcome page.
     */
    public getPromoImageUrls(): ReadonlyArray<string> {
        return this.promoImageUrls;
    }
    /**
     * Set the path to the promo images of the widescreen welcome page.
     *
     * @param array of urls. The path to the promo images of the widescreen welcome page.
     */
    public setPromoImageUrls(urls: string[]): void {
        this.promoImageUrls = Array.from(urls);
        this.makeDirty();
    }
    /**
     * Get the path to the floor plan image of this venue.
     *
     * @return The path to the floor plan image of this venue.
     */
    public getFloorPlanUrl(): string | null {
        return this.floorPlanUrl;
    }
    /**
     * Set the path to the floor plan image of this venue.
     *
     * @param url  The new path for the floor plan image of this venue.
     */
    public setFloorPlanUrl(url: string | null): void {
        this.floorPlanUrl = url;
        this.makeDirty();
    }
    /**
     * Get the time to wait while the user is idle to automatically return to the home page of this venue.
     *
     * @returns The auto home time in seconds, or `null` if no auto home timer should be set.
     */
    public getAutoHomeTime(): number | null {
        return this.autoHomeTime;
    }
    /**
     * Set the time to wait while the user is idle to automatically return to the home page of this venue.
     *
     * @param time  The new auto home time in seconds, or `null` if no auto home timer should be set. Defaults to `null`.
     * Passing `0` is interpreted as `null`.
     * @throws The auto home time should be a positive number, or `null`.
     */
    public setAutoHomeTime(time: number | null = null): void {
        if (time !== null && (time < 0 || isNaN(time))) {
            throw new Error('The auto home time should be a positive number, or null.');
        }
        this.autoHomeTime = time || null;
        this.makeDirty();
    }
    /**
     * Get the time to rotate promo images on the venue site.
     *
     * @returns The time to rotate promo images on the venue site.
     */
    public getPromoRotationTime(): number {
        return this.promoRotationTime;
    }
    /**
     * Set the time to rotate promo images on the venue site.
     *
     * @param time  The new promo rotation time in seconds.
     *
     * @throws The promo rotation time should be a positive number.
     */
    public setPromoRotationTime(time: number): void {
        this.promoRotationTime = time;
        this.makeDirty();
    }
    /**
     * Get the primary color of this venue.
     *
     * @returns The primary color of this venue, in hexadecimal format.
     */
    public getPrimaryColor(): string {
        return this.primaryColor;
    }
    /**
     * Set the primary color of this venue.
     *
     * @param color  The new primary color for this venue, in hexadecimal format.
     */
    public setPrimaryColor(color: string): void {
        this.primaryColor = color;
        this.makeDirty();
    }
    /**
     * Get the secondary color of this venue.
     *
     * @returns The secondary color of this venue, in hexadecimal format.
     */
    public getSecondaryColor(): string {
        return this.secondaryColor;
    }
    /**
     * Set the secondary color of this venue.
     *
     * @param color  The new secondary color for this venue, in hexadecimal format.
     */
    public setSecondaryColor(color: string): void {
        this.secondaryColor = color;
        this.makeDirty();
    }
    /**
     * Get the menu text color of this venue.
     *
     * @returns The menu text color of this venue, in hexadecimal format.
     */
     public getMenuTextColor(): string {
        return this.menuTextColor;
    }
    /**
     * Set the primary color of this venue.
     *
     * @param color  The new primary color for this venue, in hexadecimal format.
     */
    public setMenuTextColor(color: string): void {
        this.menuTextColor = color;
        this.makeDirty();
    }

    /**
     * Get the id of the user that created this venue.
     *
     * @returns The id of the user that created this venue.
     */
    public getOwnerUserId(): string | null {
        return this.ownerUserId;
    }
    /**
     * Set the user id representing the user that created this venue.
     *
     * @param id  The new user id representing the user that created this venue.
     */
    public setOwnerUserId(id: string): void {
        this.ownerUserId = id;
    }

    /**
     * Indicate that the given {@link VenueBeer} is similar to the given {@link Beer}.
     *
     * When `beer === venueBeer.getBeer()`, this method will do nothing.
     *
     * @param beer  The {@link Beer} for which `venueBeer` is similar.
     * @param venueBeer  The {@link VenueBeer} to add to the set of similar venue beers of `beer`.
     */
    public addSimilarVenueBeer(beer: Beer, venueBeer: VenueBeer): void {
        if (beer === venueBeer.getBeer()) {
            return;
        }

        let set = this.similarVenueBeersMap.get(beer);
        if (!set) {
            set = new VenueBeerSet();
            this.similarVenueBeersMap.set(beer, set);
        }
        if (!set.has(venueBeer)) {
//            console.log("addSimilarVenueBeer: adding beer " + venueBeer.getId() + ":" + venueBeer.getBeer().getName() + " to " + beer.getName() + ":" + beer.getId());
            set.add(venueBeer);
        }
    }
    /**
     * Check whether the given {@link Beer} has any similar {@link VenueBeer}s in this venue.
     *
     * @param beer  The {@link Beer} of which the similar {@link VenueBeer}s will be checked.
     * @returns Whether the given {@link Beer} has any similar {@link VenueBeer}s in this venue.
     */
    public hasSimilarVenueBeers(beer: Beer): boolean {
        return this.similarVenueBeersMap.has(beer);
    }
    /**
     * Indicate that the given {@link VenueBeer} is not similar to the given {@link Beer} anymore.
     *
     * @param beer  The {@link Beer} for which `venueBeer` should not be similar anymore.
     * @param venueBeer  The {@link VenueBeer} to remove from the set of similar venue beers of `beer`.
     */
    public removeSimilarVenueBeer(beer: Beer, venueBeer: VenueBeer): void {
        const set = this.similarVenueBeersMap.get(beer);
        if (set) {
            set.delete(venueBeer);
            if (set.size === 0) {
                this.similarVenueBeersMap.delete(beer);
            }
        }
    }
    /**
     * Indicate that the given {@link VenueBeer} is not similar to any given {@link Beer} anymore.
     *
     * @param venueBeer  The {@link VenueBeer} to remove from the set of similar venue beers of all {@link Beer}s.
     */
    public clearSimilarVenueBeer(venueBeer: VenueBeer): void {
        for (const set of this.similarVenueBeersMap.values()) {
            set.delete(venueBeer);
        }
    }
    /**
     * Clear all similar beers from this venue
     *
     * @param venueBeer  The {@link VenueBeer} to remove from the set of similar venue beers of all {@link Beer}s.
     */
    public clearSimilarVenueBeers(): void {
        for (const set of this.similarVenueBeersMap.values()) {
            set.clear();
        }
    }
    /**
     * Get the set of {@link VenueBeer}s that are similar to the given {@link Beer}.
     *
     * If the given beer has a venue beer in this venue, that venue beer will not be included in the set.
     *
     * @param beer  The {@link Beer} to get the similar {@link VenueBeer}s for.
     * @returns The set of {@link VenueBeer}s that are similar to the given {@link Beer}. If there are no similar venue beers,
     * this set is empty.
     */
    public getSimilarVenueBeers(beer: Beer): ReadonlySet<VenueBeer> {
        return this.similarVenueBeersMap.get(beer) || new Set<VenueBeer>();
    }
    /**
     * Get the Electronic shelf label config
     *
     */
     public getElectronicShelfLabelConfig(): ElectronicShelfLabelConfig {
        return this.electronicShelfLabelConfig;
    }

    public mustShowBeerhiveDownload(): boolean {
        return this.mustShowBeerhiveDownloadValue;
    }
}
