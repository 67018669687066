import { Serializable } from './serializable';

/**
 * A class representing a way of contacting a {@link Venue}.
 */
export class ContactType implements Serializable {
    private static contactTypeMap = new Map<string, ContactType>();

    public static readonly Mail = new ContactType('mail', 'mail',
        (value) => value ? (value.startsWith('mailto:') ? value : 'mailto:' + value) : null);
    public static readonly Telephone = new ContactType('telephone', 'call',
        (value) => value ? (value.startsWith('tel:') ? value : 'tel:' + value) : null);
    public static readonly Website = new ContactType('website', 'globe', ContactType.getWebsiteHref);
    public static readonly Facebook = new ContactType('facebook', 'logo-facebook', ContactType.getWebsiteHref);
    public static readonly Instagram = new ContactType('instagram', 'logo-instagram', ContactType.getWebsiteHref);
    public static readonly Twitter = new ContactType('twitter', 'logo-twitter', ContactType.getWebsiteHref);

    public static readonly All: ReadonlyArray<ContactType> = [
        ContactType.Mail, ContactType.Telephone, ContactType.Website, ContactType.Facebook, ContactType.Instagram, ContactType.Twitter
    ];

    private key: string;
    private icon: string;
    public readonly getHref: (value: string) => string | null;
    private constructor(key: string, icon: string, getHref: (value: string) => string | null) {
        this.key = key;
        this.icon = icon;
        this.getHref = getHref;

        ContactType.contactTypeMap.set(key, this);
    }

    private static getWebsiteHref(value: string): string | null {
        return value && value !== null ?
                  (value.startsWith('http://') || value.startsWith('https://')) ? value : 'http://' + value :
                  null;
    }
    public static getByKey(key: string): ContactType | null {
        return this.contactTypeMap.get(key) || null;
    }

    /**
     * Get the unique key of this contact type.
     *
     * @returns The unique key of this contact type.
     */
    public getKey(): string {
        return this.key;
    }
    /**
     * Get the name of the [Ion icon](https://ionicons.com/) representing this contact type.
     */
    public getIcon(): string {
        return this.icon;
    }

    /**
     * @inheritDoc
     */
    public toJSON(): any {
        return this.key;
    }
}
/**
 * A class representing a map from contact types to the contact address belonging to the contact type.
 */
export class ContactTypeMap extends Map<ContactType, string> {
    public static read(data: [string, string][]): ContactTypeMap {
        const result = new ContactTypeMap();
        data.forEach(([key, value]) => {
            const type = ContactType.getByKey(key);
            if (type) {
                result.set(type, value);
            }
        });
        return result;
    }
    public getHref(type: ContactType): string | null {
        const value = this.get(type);
        return value && type.getHref(value) || null;
    }
    public set(type: ContactType, value: string | null): this {
        if (value) {
            super.set(type, value);
        } else {
            super.delete(type);
        }
        return this;
    }
}
