import { TranslateService } from '@ngx-translate/core';
import { ModelTable } from '../model-table';
import { Beer } from './beer';
import { Cleaner } from '../../cleaner';
import { BreweryTable } from './brewery-table';
import { AddBeerRequest } from '../../data-transfer/add-beer-request';
import { LoginGuard } from '../../../guards/login/login.guard';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { modelUrls, modelUrls as urls } from '../../../model-urls';
import { RefdataService } from '../../../services/refdata/refdata.service';
import { VenueService } from '../../../services/venue/venue.service';

/**
 * An abstract {@link ModelTable} of {@link Beer}s responsible for reading instances.
 */
export abstract class BeerTable extends ModelTable<Beer> {
  constructor(private translateService: TranslateService,
              private breweryTable: BreweryTable,
              private loginGuard: LoginGuard,
              private http: HttpClient) {
    super([breweryTable]);
  }

  /** @inheritDoc */
  public createInstance(): Beer {
    return new Beer(this.translateService, this, this.breweryTable);
  }
  /** @throws Serialization of beers is not supported yet */
//  public serializeInstance(instance: Beer): any {
//    throw new Error('Serialization of beers is not implemented yet');
//  }
  public serializeInstance(instance: Beer): any {
    return {
      id: instance.getId(),
      name: instance.getName(),
      abv: instance.getABV(),
      ibu: instance.getIBU(),
      ebc: instance.getEBC(),
      logoUrl: instance.getRawLogoUrl(),
      brewery_id: instance.getBrewery()?.getId(),

      creationTs: instance.getCreationTime(),
      updateTs: instance.getLastUpdate()
    };
  }
  /** @inheritDoc */
  public applyRawData(data: any, instance: Beer): void {
    instance.setId(Cleaner.parseId(data.id));
    instance.setCreationTime(Cleaner.parseDate(data.creationTs));
    instance.setLastUpdate(Cleaner.parseDate(data.updateTs));
    Cleaner.safeApply(instance, instance.setName, data.name);
    Cleaner.safeApply(instance, instance.setABV, data.abv);
    Cleaner.safeApply(instance, instance.setEBC, data.ebc);
    Cleaner.safeApply(instance, instance.setIBU, data.ibu);
    Cleaner.safeApply(instance, instance.setLogoUrl, data.logo);

    const breweryId = Cleaner.parseId(data.brewery_id);
    if (breweryId === null) {
      console.warn(`Beer ${data.name} has a null brewery.`);
    } else if (!this.breweryTable.has(breweryId)) {
      console.warn(`Brewery of ${data.name} not found: no brewery with id ${breweryId}.`);
    } else {
      instance.setBrewery(this.breweryTable.get(breweryId));
    }
  }


  /**
   * Save an instance to the back-end and call super.saveInstance to add this instance to the table (if not present yet).
   *
   * This method should only be called from {@link PersistentModel.save}.
   *
   * @param instance  The instance to save.
   * @returns A promise that resolves when the instance is saved.
   * @throws Saved instance has a null id.
   */
  public saveInstance(instance: Beer): Promise<void> {

    let userId: string | null = this.loginGuard.getUserToken() != null ? this.loginGuard.getUserToken() : "anonymous";

    let addBeerRequest = new AddBeerRequest(
      userId,
      0.0,
      0.0,
      instance.getName(),
      instance.getABV(),
      instance.getEBC(),
      instance.getIBU(),
      instance.getBrewery()?.getId(),
      instance.getTenant(),
      instance.getTenantId()
    );

    const options = { headers: new HttpHeaders({
      'x-api-key': environment['lambdaApiKey'],
      'Content-Type': 'application/json'
    })};

    return this.http.post(environment[modelUrls.beer.base] + modelUrls.beer.CREATE, addBeerRequest, options)
      .toPromise().then((response: any) => {

        instance.setId(response.id);
        super.saveInstance(instance);

      }).catch((errorResponse) => {
      console.log(errorResponse);
      throw errorResponse;
    });
  }

}
