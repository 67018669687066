import { Serializable } from './../serializable';
import { PersistentModel } from './persistent-model';
import { ModelTable } from './model-table';

/**
 * A one-to-many relation between two {@link PersistentModel}s.
 *
 * @typeParam Head  The type of the head of the relation.
 * @typeParam Target  The type of the target of the relation.
 *
 * @Suggestion An elegant alternative would be to represent relations with
 * [decorators](https://www.typescriptlang.org/docs/handbook/decorators.html). A relation from a venue beer to a beer
 * could for example look like the following.
 * ```
 * private beer: Relation<VenueBeer, Beer>; // Current version
 *
 * @Relation
 * private beer: Beer; // Possible version with a decorator
 * ```
 */
export class Relation<Head extends PersistentModel, Target extends PersistentModel> implements Serializable {
    private head: Head;
    private table: ModelTable<Target>;
    private id: string | null;

    /**
     * Create a new relation with a given head and a given table that contains possible targets.
     * The initial target is set to `null`.
     *
     * @param head  The head of the relation.
     * @param table  The table of possible targets of this relation.
     */
    public constructor(head: Head, table: ModelTable<Target>) {
        this.head = head;
        this.table = table;
        this.id = null;
    }
    /**
     * Set the target of this relation to the given instance, or `null` to break the current relation.
     * If the given instance does not equal the current target, the head is made dirty.
     *
     * @param instance  The new target of this relation.
     * @throws The given instance must have a non-null id. This is not the case if the given instance has never been saved to
     * the back-end, and only exists locally.
     */
    public set(instance: Target | null): void {
        if (this.get() !== instance) {
            if (instance === null) {
                this.id = null;
            } else {
                if (instance.getId() === null) {
                    throw new Error(
                        'The given instance must have a non-null id. ' +
                        'This is not the case if the given instance has never been saved to the back-end, and only exists locally.');
                }
                this.id = instance.getId();
            }
            this.head.makeDirty();
        }
    }
    /**
     * Get the current target of this relation, or `null` if there is none.
     *
     * @returns  The current target of this relation, or `null` if there is none.
     */
    public get(): Target | null {
        if (this.id === null) {
            return null;
        }
        return this.table.get(this.id);
    }

    /** @inheritDoc */
    public toJSON(): any {
        return this.id;
    }
}

/**
 * A many-to-many relation between two {@link PersistentModel}s.
 *
 * @typeParam Head  The type of the head of the relation.
 * @typeParam Target  The type of the target of the relation.
 */
export class ManyToManyRelation<Head extends PersistentModel, Target extends PersistentModel> implements Serializable {
    private head: Head;
    private table: ModelTable<Target>;
    private ids: Set<string>;

    /**
     * Create a new many-to-many relation with a given head and a given table that contains possible targets.
     * Initially, the targets are an empty set.
     *
     * @param head  The head of the relation.
     * @param table  The table of possible targets of this relation.
     */
    public constructor(head: Head, table: ModelTable<Target>) {
        this.head = head;
        this.table = table;
        this.ids = new Set<string>();
    }
    /**
     * Add an instance to the targets of this relation.
     *
     * @param instance  The instance to add as target.
     * @throws The given instance must have a non-null id. This is not the case if the given instance has never been saved to
     * the back-end, and only exists locally.
     */
    public add(instance: Target): void {
        const id = instance.getId();
        if (id === null) {
            throw new Error(
                'The given instance must have a non-null id. ' +
                'This is not the case if the given instance has never been saved to the back-end, and only exists locally.');
        }
        this.ids.add(id);
        this.head.makeDirty();
    }
    /**
     * Remove an instance from the targets of this relation.
     *
     * @param instance  Remove an instance from this relation.
     */
    public remove(instance: Target): void {
        const id = instance.getId();
        if (id !== null) {
            this.ids.delete(id);
        }
    }
    /**
     * Reset the relation to its initial state by removing all targets.
     */
    public clear(): void {
        this.ids.clear();
    }
    /**
     * Get the current set of targets of this relation.
     *
     * @returns The current set of targets of this relation.
     */
    public get(): Set<Target> {
        const instances = new Set<Target>();
        this.ids.forEach((id) => instances.add(this.table.get(id)));
        return instances;
    }

    /** @inheritDoc */
    public toJSON(): any {
        return Array.from(this.ids);
    }
}

