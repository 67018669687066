import { VenueBeer } from "./persistency/persistent-models/venue-beer";


export class VenueBeerSet extends Set<VenueBeer> {

    public has(value: VenueBeer): boolean {
        let result: boolean = false;
        this.forEach((venueBeer) => {
            if (value.equals(venueBeer)) {
                result = true;
            }
        });
        return result;
    }
}