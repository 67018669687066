import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VenueService } from '../venue/venue.service';
import { Statistic } from '../../models/statistic';
import { map } from 'rxjs/operators';

interface BackendStatistic {
  name: string;
  statistic: number;
}

/**
 * A service responsible for loading statistics about the currently selected venue.
 */
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private domain: string;

  private topUrl: string;
  private pageViewsUrl: string;
  private topSearchesUrl: string;
  private topCharacteristicSearchesUrl: string;
  private searchesPerTypeUrl: string;

  public constructor(private http: HttpClient, private venueService: VenueService) {
    this.topUrl = 'topbeers';
    this.pageViewsUrl = 'sessions';
    this.topSearchesUrl = 'topsearch';
    this.topCharacteristicSearchesUrl = 'topcharacteristic';
    this.searchesPerTypeUrl = 'searchespertype';
    this.domain = environment.apiBaseUrl + 'analytics/';
  }

  private getOptions(days: number, per: string | null = null): {params: HttpParams, withCredentials: boolean} {
    const venue = this.venueService.getSelectedVenue(true);
    const venueId = venue.getId();
    if (venueId === null) {
      throw new Error('Selected venue has a null id!');
    }
    let params = new HttpParams()
      .set('venue', venueId)
      .set('days', days.toString());
    if (per) {
      params = params.set('per', per);
    }
    return { params: params,
      withCredentials: true };
  }

  /**
   * Get a list of {@link Beer} ids that were visited the most frequent by users.
   */
  public getTopIDs(days: number = 7): Observable<Statistic[]> {
    return this.http.get<BackendStatistic[]>(this.domain + this.topUrl, this.getOptions(days))
      .pipe(map((stats) => stats.map((stat) => ({ label: stat.name, statistic: stat.statistic }))));
  }

  /**
   * Get a list of search queries that were entered the most frequent by users.
   */
  public getTopSearches(days: number = 7): Observable<Statistic[]> {
    return this.http.get<BackendStatistic[]>(this.domain + this.topSearchesUrl, this.getOptions(days))
      .pipe(map((stats) => stats.map((stat) => ({ label: stat.name, statistic: stat.statistic }))));
  }

  /**
   * Get a list of characteristics ({@link Selector} label keys) that were used the most frequent by users.
   *
   * A query is formatted as
   * ```
   * aroma selector keys seperated by '+'|color selector keys seperated by '+'|other selector keys seperated by '+'
   * ```
   *
   * Example: a characteristics query for international beers with low ABV and a hoppy taste would look like this.
   * ```
   * hoppy||international+lowalcohol
   * ```
   */
  public getTopCharacteristicSearches(days: number = 7): Observable<Statistic[]> {
    return this.http.get<BackendStatistic[]>(this.domain + this.topCharacteristicSearchesUrl, this.getOptions(days))
      .pipe(map((stats) => stats.map((stat) => ({ label: stat.name, statistic: stat.statistic }))));
  }

  /**
   * Get the number of sessions of users that visited the currently selected venue.
   */
  public getSessionsOverTime(days: number = 7, per: 'day'|'week'|'month' = 'day'): Observable<number[]> {
    return this.http.get<number[]>(this.domain + this.pageViewsUrl, this.getOptions(days, per));
  }

  /**
   * Get the number of searches per type.
   */
  public getSearchesPerType(days: number = 7): Observable<Statistic[]> {
    return this.http.get<BackendStatistic[]>(this.domain + this.searchesPerTypeUrl, this.getOptions(days))
      .pipe(map((stats) => stats.map((stat) => ({ label: stat.name, statistic: stat.statistic }))));
  }
}

