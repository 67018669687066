import { Injectable } from '@angular/core';
import colors from '../../models/defaults/colors.json';

/**
 * A service responsible for setting the primary and secondary color of the website.
 */
@Injectable({
  providedIn: 'root'
})
export class ColorService {
  public constructor() {}

  public setPrimaryColor(primary: string = colors.primaryColor): void {
    // set primary, default to default primary
    const el: any = document.querySelector(':root');
    el.style.setProperty('--ion-color-primary', primary);
  }

  public setSecondaryColor(secondary: string = colors.secondaryColor): void {
    // set secondary, default to default secondary
    const el: any = document.querySelector(':root');

    el.style.setProperty('--ion-color-secondary', secondary);
    el.style.setProperty(
      '--ion-color-secondary-opacity',
      this.hexToRgba(secondary, 0.5)
    );
    el.style.setProperty(
      '--ion-color-secondary-opacity-hover',
      this.hexToRgba(secondary, 0.7)
    );
  }

  public setMenuTextColor(menuTextColor: string = colors.menuTextColor): void {
    // set menu text color, default to default menu text color
    const el: any = document.querySelector(':root');
    el.style.setProperty('--bh-menu-text-color', menuTextColor);
  }

  public resetColors(): void {
    this.setPrimaryColor();
    this.setSecondaryColor();
    this.setMenuTextColor();
  }

  private hexToRgba(hex: string, opacity: number) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      return (
        'rgba(' +
        parseInt(result[1], 16) +
        ', ' +
        parseInt(result[2], 16) +
        ', ' +
        parseInt(result[3], 16) +
        ', ' +
        opacity +
        ')'
      );
    } else {
      return hex;
    }
  }
}
