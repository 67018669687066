import { SubscribableSubject } from './subscribable';

/**
 * A map which can be subscribed to to listen for changes.
 */
export class MapSubject<Key, Value> extends SubscribableSubject<MapSubject<Key, Value>> implements Map<Key, Value> {
    private _map = new Map<Key, Value>();

    public get(key: Key): Value | undefined {
        return this._map.get(key);
    }
    public set(key: Key, value: Value): this {
        if (value === null) {
            this.delete(key);
            return this;
        } else {
            this._map.set(key, value);
            this.next(this);
            return this;
        }
    }
    public delete(key: Key): boolean {
        const present = this._map.delete(key);
        this.next(this);
        return present;
    }
    public clear(): void {
        this._map.clear();
        this.next(this);
    }

    public has(key: Key): boolean {
        return this._map.has(key);
    }
    public forEach(callbackfn: (value: Value, key: Key, map: Map<Key, Value>) => void, thisArg?: any): void {
        return this._map.forEach(callbackfn, thisArg);
    }
    public get size(): number { return this._map.size; }
    public [Symbol.iterator](): IterableIterator<[Key, Value]> {
        return this._map[Symbol.iterator]();
    }
    public entries(): IterableIterator<[Key, Value]> {
        return this._map.entries();
    }
    public keys(): IterableIterator<Key> {
        return this._map.keys();
    }
    public values(): IterableIterator<Value> {
        return this._map.values();
    }
    public get [Symbol.toStringTag](): string { return 'MapSubject'; }
}
