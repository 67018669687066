import { environment } from '../../../environments/environment';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, ParamMap } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserFeedbackService } from '../../services/user-feedback/user-feedback.service';
import { ColorService } from '../../services/color/color.service';
import { CookieService } from '../../services/cookie/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  private userToken: string | null = null;

  private allowedEmails = ['dev', 'demo', 'prikentik'];

  public constructor(
    private userFeedbackService: UserFeedbackService,
    private colorService: ColorService,
    private cookies: CookieService,
    private router: Router
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    this.tryLogin(route.queryParamMap);
    if (this.isLoggedIn()) {
      return true;
    }
    return this.router.parseUrl('admin/login');
  }

  public getUserToken(): string | null {
    return this.userToken;
  }

  public isLoggedIn(): boolean {
    return this.userToken !== null;
  }

  private tryLogin(queryParamMap: ParamMap): void {
    let uid = queryParamMap.get('uid');
    if (uid) {
      this.userToken = uid;
    } else if (!this.userToken) {
      uid = this.cookies.getCookie(environment.userCookieName);
      if (uid) {
        this.userToken = uid;
      }
    }
    if (this.userToken) {
      this.cookies.setCookie(environment.userCookieName, this.userToken);
    }
  }

  public login(email: string, pass: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.allowedEmails.indexOf(email) > -1) {
        this.userToken = email + '-admin';
        resolve('login success');
      } else {
        let allowedString = '';
        for (const allowedEmail of this.allowedEmails) {
          allowedString += allowedEmail + ', ';
        }
        allowedString = allowedString.slice(0, -2);
        this.userFeedbackService.notifyWithInteraction(
          'Wrong email, allowed are: ' + allowedString
        );
        reject('login failed');
      }
    });
  }

  public logout(): void {
    this.colorService.resetColors();
    this.userToken = null;
    this.cookies.deleteCookie(environment.userCookieName);
  }
}
