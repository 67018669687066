import { BeerServing } from './beer-serving';
import { VenueBeer } from './persistency/persistent-models/venue-beer';

/**
 * A class representing an order of a specific {@link BeerServing} of a {@link VenueBeer}.
 */
export class Order {
    private venueBeer: VenueBeer;
    private beerServing: BeerServing;
    private amount: number;

    public constructor(venueBeer: VenueBeer, serving: BeerServing, amount: number = 0) {
        if (!venueBeer.getBeerServings().includes(serving)) {
            throw new Error('Trying to create invalid beer order.');
        }
        this.venueBeer = venueBeer;
        this.beerServing = serving;
        this.amount = amount;
    }

    /**
     * Get the venue beer of this order.
     *
     * @returns The venue beer of this order.
     */
    public getVenueBeer(): VenueBeer {
        return this.venueBeer;
    }
    /**
     * Get the beer serving of this order.
     *
     * @returns The beer serving of this order.
     */
    public getBeerServing(): BeerServing {
        return this.beerServing;
    }
    /**
     * Get the amount of this order.
     *
     * @returns The amount of this order.
     */
    public getAmount(): number {
        return this.amount;
    }
    /**
     * Create a new order of this serving with the specified amount.
     *
     * @returns A new order of the same venue beer and serving as this one, but with the specified amount.
     */
    public setAmount(amount: number): Order {
        return new Order(this.venueBeer, this.beerServing, amount);
    }
    /**
     * Create a new order of this serving with an amount added relative to the current amount.
     *
     * @returns A new order of the same venue beer and serving as this one, but with as amount the sum of the given amount and
     * the amount of this order.
     */
    public addAmount(amount: number): Order {
        return new Order(this.venueBeer, this.beerServing, this.amount + amount);
    }
    /**
     * Add the amounts of two orders of the same serving together.
     *
     * @returns A new order of the same venue beer and serving, but with as amount the sum of the amount of the given order and
     * the amount of this order.
     * @throws Trying to join orders for different servings.
     */
    public join(order: Order): Order {
        if (this.venueBeer !== order.venueBeer || this.beerServing !== order.beerServing) {
            throw new Error('Trying to join orders for different servings.');
        }
        return this.addAmount(order.amount);
    }
    /**
     * Get the price of this order.
     *
     * @returns The price of the serving of this order, multiplied by the amount. If the price of
     * the serving is `null`, returns `null`.
     */
    public getPrice(): number | null {
        const unitPrice = this.getBeerServing().getCurrentPrice();
        if (unitPrice !== null) {
            return this.getAmount() * unitPrice;
        }
        return null;
    }
}
