import { SubscribableSubject } from './subscribable';

/**
 * A class representing a physical address, which can be subscribed to to listen for changes.
 */
export class Address extends SubscribableSubject<Address> {
    private country: string | null = null;
    private region: string | null = null; // state/province/region
    private city: string | null = null;
    private postalCode: string | null = null; // ZIP/postal code
    private street: string | null = null;
    private streetNumber: string | null = null;

    private longitude: number | null = null;
    private latitude: number | null = null;

    /**
     * Create a new address from the given raw data.
     *
     * @param  data The raw data representing an address.
     */
    public static read(data: any): Address {
        const instance = Object.assign(new Address(), data);
        return instance;
    }

    /**
     * Get the country of this address.
     *
     * @returns The country of this address, or null if not known.
     */
    public getCountry(): string | null {
        return this.country;
    }
    /**
     * Set the country of this address.
     *
     * @param country  The new country of this address, or null if not known.
     */
    public setCountry(country: string | null): void {
        this.country = country;
        this.next(this);
    }
    /**
     * Get the region, province or state of this address, whichever is the most appropriate.
     *
     * @returns The region, province or state of this address, or null if not known.
     */
    public getRegion(): string | null {
        return this.region;
    }
    /**
     * Set the region, province or state of this address, whichever is the most appropriate.
     *
     * @param region  The new region, province or state of this address, or null if not known.
     */
    public setRegion(region: string | null): void {
        this.region = region;
        this.next(this);
    }
    /**
     * Get the city name of this address.
     *
     * @returns The city name of this address, or null if not known.
     */
    public getCity(): string | null {
        return this.city;
    }
    /**
     * Set the city name of this address.
     *
     * @param country  The new city name of this address, or null if not known.
     */
    public setCity(city: string | null): void {
        this.city = city;
        this.next(this);
    }
    /**
     * Get the postal code of this address.
     *
     * @returns The postal code of this address, or null if not known.
     */
    public getPostalCode(): string | null {
        return this.postalCode;
    }
    /**
     * Set the postal code of this address.
     *
     * @param country  The new postal code of this address, or null if not known.
     */
    public setPostalCode(postalCode: string | null): void {
        this.postalCode = postalCode;
        this.next(this);
    }
    /**
     * Get the street name of this address.
     *
     * @returns The street name of this address, or null if not known.
     */
    public getStreet(): string | null {
        return this.street;
    }
    /**
     * Set the street name of this address.
     *
     * @param country  The new street name of this address, or null if not known.
     */
    public setStreet(street: string | null): void {
        this.street = street;
        this.next(this);
    }
    /**
     * Get the street number of this address.
     *
     * @returns The street number of this address, or null if not known.
     */
    public getStreetNumber(): string | null {
        return this.streetNumber;
    }
    /**
     * Set the street number of this address.
     *
     * @param country  The new street number of this address, or null if not known.
     */
    public setStreetNumber(streetNumber: string | null): void {
        this.streetNumber = streetNumber;
        this.next(this);
    }

    /**
     * Get the longitude of this address.
     *
     * @returns The longitude in degrees of this address, or null if not known.
     */
    public getLongitude(): number | null {
        return this.longitude;
    }
    /**
     * Set the longitude of this address.
     *
     * @param country  The new longitude in degrees of this address, or null if not known.
     */
    public setLongitude(longitude: number | null): void {
        this.longitude = longitude;
        this.next(this);
    }
    /**
     * Get the latitude of this address.
     *
     * @returns The latitude in degrees of this address, or null if not known.
     */
    public getLatitude(): number | null {
        return this.latitude;
    }
    /**
     * Set the latitude of this address.
     *
     * @param country  The new latitude in degrees of this address, or null if not known.
     */
    public setLatitude(latitude: number | null): void {
        this.latitude = latitude;
        this.next(this);
    }
}
