import { Injectable } from '@angular/core';

/**
 * A service responsible for logging.
 */
@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  public error(source: string, message: string, error: any) {
    this.log(source, 'error', message);
    console.error(error);
  }

  public warn(source: string, message: string) {
    this.log(source, 'warn', message);
  }

  public info(source: string, message: string) {
    this.log(source, 'info', message);
  }

  public debug(source: string, message: string) {
    this.log(source, 'debug', message);
  }

  private log(source: string, type: string, message: string) {
    const timeStamp = new Date();

    console.log(
      timeStamp +
        ' - ' +
        source +
        ' - ' +
        type.toUpperCase() +
        ': ' +
        message
    );
  }
}
