<ion-app>
  <ion-menu *ngIf="venue" contentId="main-content">
    <div
      style="background-color: var(--ion-color-primary); height: 100%; width: 100%"
    >
      <ion-icon
        name="close"
        (click)="closeMenu()"
        style="position: absolute; top: 2px; right: 2px; color: var(--ion-color-secondary); font-size: var(--font-size-large); z-index: 200; cursor: pointer;"
      ></ion-icon>
      <app-venue-contact [showTitle]="true" [venue]="venue" *ngIf="venue!.hasAnyContact()"></app-venue-contact>
      <div class="menu-divider" *ngIf="venue.hasAnyContact()"></div>
      <app-venue-about *ngIf="venue!.getAbout(translateService.currentLang) !== null" [abouts]="venue!.getAbouts()"></app-venue-about>
      <div class="menu-divider" *ngIf="venue!.getAbout(translateService.currentLang) !== null"></div>
      <app-venue-hours *ngIf="venue!.hasOpeningTimes()" [venue]="venue"></app-venue-hours>
      <div
        *ngIf="screenType != screenTypeDesktop && venue!.hasOpeningTimes()"
        class="menu-divider"
      ></div>
      <app-set-language
        *ngIf="screenType != screenTypeDesktop"
        [showTitle]="true"
      ></app-set-language>
      <div style="position: absolute; bottom: 5px; width: 100%; text-align: center;">
        <app-powered-by></app-powered-by>
      </div>
    </div>
  </ion-menu>
  <app-loading-overlay
    *ngIf="showLoading"
    style="position: absolute; top: 0; left: 0; width: 100vw; height: 100vh; z-index: 9999;"
  ></app-loading-overlay>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>
