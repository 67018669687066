import { BeerServing } from './../../models/beer-serving';
import { VenueService } from './../../services/venue/venue.service';
import { Injectable } from '@angular/core';
import { VenueBeer } from '../../models/persistency/persistent-models/venue-beer';
import { Order } from '../../models/order';
import { BehaviorSubject } from 'rxjs';
import { LoggerService } from '../logger/logger.service';

/**
 * A service responsible for keeping track of orders.
 */
@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private orders = new Map<BeerServing, Order>();

  /**
   * A behavior subject which can be subscribed to to keep track of the total amount of orders.
   */
  public readonly $orderCount = new BehaviorSubject<number>(0);

  public constructor(private logger: LoggerService) {
  }

  private publish() {
    this.$orderCount.next(this.getTotalOrderAmount());
  }
  /**
   * Clear all orders.
   */
  public clearOrders() {
    this.orders = new Map<BeerServing, Order>();
    this.publish();
  }
  /**
   * Add an order. If an order for the same serving exists, the given order will be {@link Order.join | joined} with the existing one.
   *
   * @param order  The order to add.
   */
  public addOrder(order: Order) {
    const oldOrder = this.orders.get(order.getBeerServing());
    if (!oldOrder) {
      if (order.getAmount() > 0) {
        this.orders.set(order.getBeerServing(), order);
      }
    } else {
      const newOrder = oldOrder.join(order);
      if (newOrder.getAmount() > 0) {
        this.orders.set(order.getBeerServing(), newOrder);
      } else {
        this.orders.delete(order.getBeerServing());
      }
    }
    this.publish();
  }
  /**
   * Set an order. If an order for the same serving exists, the given order will replace the existing one.
   *
   * @param order  The order to set.
   */
  public setOrder(order: Order) {
    if (order.getAmount() > 0) {
      this.orders.set(order.getBeerServing(), order);
    } else {
      this.orders.delete(order.getBeerServing());
    }
    this.publish();
  }
  /**
   * Get all orders, optionally limited to the orders of a specified {@link VenueBeer}.
   *
   * @param venueBeer  The {@link VenueBeer} to limit the resulting orders to.
   * @returns If no venue beer was given, a list of all orders. Otherwise a list of all orders for the given venue beer.
   */
  public getOrders(venueBeer?: VenueBeer): Order[] {
    if (venueBeer) {
      return Array.from(this.orders.values()).filter((order) => order.getVenueBeer() === venueBeer);
    }
    return Array.from(this.orders.values());
  }
  /**
   * Get all venue beers that have orders.
   *
   * @returns A set of venue beers that have orders.
   */
  public getVenueBeersWithOrders(): Set<VenueBeer> {
    const vbs = new Set<VenueBeer>();
    this.orders.forEach((o) => vbs.add(o.getVenueBeer()));
    return vbs;
  }
  /**
   * Get the total price of all orders.
   *
   * @returns The total price of all orders.
   */
  public getTotalPrice(): number {
    return this.getOrders().map(o => o.getPrice() || 0).reduce((a, b) => a + b, 0);
  }
  /**
   * Get the total amount of orders.
   *
   * @returns The total amount of orders.
   */
  public getTotalOrderAmount(): number {
    return this.getOrders().map(o => o.getAmount()).reduce((a, b) => a + b, 0);
  }
  /**
   * Get the order of the given venue beer with the given serving.
   */
  public getOrder(venueBeer: VenueBeer, beerServing: BeerServing): Order {
    const order = this.orders.get(beerServing);
    if (!order) {
      return new Order(venueBeer, beerServing, 0);
    }
    return order;
  }
}
