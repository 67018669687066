import { ScreenVersionListener } from './../../models/screen-version-listener';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent extends ScreenVersionListener implements OnInit, OnDestroy {
  public loadingState = 0;
  private timer: NodeJS.Timeout | null = null;

  public constructor() {
    super();
  }

  public ngOnInit(): void {
    this.startTimer();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private startTimer() {
    this.timer = setTimeout(() => {
      if (this.loadingState === 4) {
        this.loadingState = 0;
      } else {
        this.loadingState++;
      }
      this.startTimer();
    }, 100);
  }
}
