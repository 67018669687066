import { environment } from './../../../../environments/environment';
import { Cleaner } from '../../cleaner';
import { AnalysisTable } from './analysis-table';
import { LoginGuard } from '../../../guards/login/login.guard';
import { Beer } from './beer';
import { TranslateService } from '@ngx-translate/core';
import { BeerServing } from '../../beer-serving';
import { VenueBeer } from './venue-beer';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModelTable } from '../model-table';
import { Unit, Units } from '../../unit';
import { Venue } from './venue';
import { modelUrls as urls } from '../../../model-urls';
import { RefdataService } from '../../../services/refdata/refdata.service';
import { BeerTag } from './beer-tag';
import { VenueFacadeService } from '../../../services/venue-facade/venue-facade.service';

/**
 * A {@link ModelTable} of {@link VenueBeer}s responsible for reading, saving, deleting and loading instances.
 */
export class VenueBeerTable extends ModelTable<VenueBeer> {
  private rawDataPromise: Promise<any> | null = null;
  private rawSimilarBeerData: any[] | null = null;

  private readonly venue: Venue;
  private readonly analysisTable: AnalysisTable;

  /**
   * Get the venue beer corresponding to the given {@link Beer} using an index for efficiency.
   *
   * @param beer  The beer to look for.
   * @returns The venue beer corresponding to the given {@link Beer}, or `null` if no was found.
   */
  public readonly getVenueBeer: (beer: Beer) => VenueBeer | null;
  constructor(
    venue: Venue, private beerTagTable: ModelTable<BeerTag>, private http: HttpClient,
    private refdata: RefdataService, private beerTable: ModelTable<Beer>,
    private translateService: TranslateService, private loginGuard: LoginGuard,
    private venueFacadeService: VenueFacadeService
  ) {
    super([beerTable, beerTagTable]);
    this.venue = venue;
    const that = this;
    this.analysisTable = new class extends AnalysisTable {
      protected getRawData(): Promise<any[]> {
        return that.getRawAnalysisData();
      }
    };
    this.addDependency(this.analysisTable);

    this.getVenueBeer = this.createIndex((vb) => vb.getBeer());
  }

  /**
   * Save the given {@link VenueBeer} to the back-end, and adds it to this table if not already present.
   *
   * @param venueBeer  The {@link VenueBeer} instance to save.
   * @returns A promise that resolves when the given instance is saved.
   */
  public saveInstance(venueBeer: VenueBeer): Promise<void> {
    const userId = this.loginGuard.getUserToken();
    if (!venueBeer.getOwnerUserId() && userId) {
        venueBeer.setOwnerUserId(userId);
    }
    const serialized = this.serializeInstanceForSaving(venueBeer);
    serialized.userid = userId;
    serialized.availability = 'available';
    const options = { headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })};
    let storageUrl = environment[urls.venuebeer.base];
    storageUrl += urls.venuebeer.POST;
    return this.http.post<any>(storageUrl, serialized, options).toPromise().then((response) => {
      const id = Cleaner.parseId(response);
      if (id === null) {
        throw new Error('Expected a response while saving (' + response + ')');
      }
      venueBeer.setId(id);
      return super.saveInstance(venueBeer);
    }).catch((errorResponse) => {
      console.log(errorResponse);
      throw errorResponse;
    });
  }
  /**
   * Delete the given {@link VenueBeer} from the back-end, and removes it from this table.
   * Also removes all occurences as similar beer.
   *
   * @param venueBeer  The {@link VenueBeer} instance to delete.
   * @returns A promise that resolves when the given instance is deleted.
   */
  public deleteInstance(venueBeer: VenueBeer): Promise<void> {
    this.venue.clearSimilarVenueBeer(venueBeer);

    const serialized = this.serializeInstanceForSaving(venueBeer);
    serialized.userid = this.loginGuard.getUserToken();
    serialized.availability = 'unavailable';
    const options = { headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })};
    let storageUrl = environment[urls.venuebeer.base];
    storageUrl += urls.venuebeer.POST;
    return this.http.post<any>(storageUrl, serialized, options).toPromise().then(() => {
      return super.deleteInstance(venueBeer).then(() => venueBeer.getAnalysis()?.delete());
    }).catch((errorResponse) => {
      console.log(errorResponse);
      throw errorResponse;
    });
  }

  private serializeInstanceForSaving(instance: VenueBeer): any {
    let result:any = this.serializeInstance(instance);
    delete result.analysis;
    return result;
  }
    /** @inheritDoc */
  public serializeInstance(instance: VenueBeer): any {
    const description: {[key: string]: string} = {};
    instance.getDescriptionMap().forEach((d, l) => description[l] = d);
    const trivia: {[key: string]: string} = {};
    instance.getTriviaMap().forEach((d, l) => trivia[l] = d);
    return {
      id: instance.getId(),
      ownerUserId: instance.getOwnerUserId(),
      creationTs: instance.getCreationTime(),
      updateTs: instance.getLastUpdate(),
      beerid: instance.getBeer().getId(),
      venueid: instance.getVenue().getId(),
      bottleImg: instance.getBottleImageUrl(),
      local: instance.isLocal(),
      new: instance.getIsNewFlag(),
      newEndDate: instance.getIsNewEndDate(),
      promo: instance.getIsPromoFlag(),
      promoStartDate: instance.getIsPromoStartDate(),
      promoEndDate: instance.getIsPromoEndDate(),
      temporary: instance.getIsTemporaryFlag(),
      temporaryEndDate: instance.getIsTemporaryEndDate(),
      servings: instance.getBeerServings().map((serving) => ({
        volume: serving.getVolume(),
        volumeUnit: serving.getVolumeUnit().getSymbol(),
        serveMethod: serving.getCasing().toString(),
        isPromo: serving.isInPromotion(),
        promoPrice: serving.getPromotionPrice(),
        price: serving.getPrice()
      })),
      tags: Array.from(instance.getBeerTags(), (tag) => tag.getId()),
      description: description,
      trivia: trivia,
      location: instance.getLocation(),
      externalReference: instance.getExternalReference(),
      locations: Array.from(instance.getLocations(), (loc) => loc),
      autoBeerDescription: instance.autoGenerateDescription(),
      analysis: instance.getAnalysis()
    };
  }

  /** @inheritDoc */
  public createInstance(): VenueBeer {
    return new VenueBeer(this.translateService, this.venue, this, this.beerTable, this.analysisTable, this.beerTagTable);
  }
  /**
   * Create a new {@link VenueBeer} from the given {@link Beer} and load its analysis and similar beers.
   *
   * @param beer  The beer to create a new {@link VenueBeer} for.
   * @returns A promise that returns the resulting {@link VenueBeer} once the analysis and similar beers are loaded.
   * @throws The id of the provided beer is null.
   */
  public createInstanceWithProfile(beer: Beer): Promise<VenueBeer> {
    const beerId = beer.getId();
    if (beerId === null) {
      throw new Error('The id of the provided beer is null.');
    }
    const beerProfileRequest = {
      userid: this.loginGuard.getUserToken(),
      beerid: beerId,
      venueid: this.venue.getId()
    };
    const options = { headers: new HttpHeaders({
      'x-api-key': environment['lambdaApiKey'],
      'Content-Type': 'application/json'
    })};
    const url = environment[urls.venuebeerprofile.base] + urls.venuebeerprofile.GET;
    return this.http.post<any>(url, beerProfileRequest, options).toPromise().then((resp) => {
      const venueBeer = this.createInstance();
      venueBeer.setBeer(beer);
      if (resp) {
        // if an existing venueBeer is found, adopt its data
        if (resp.venuebeer) {
          this.applyRawData(resp.venuebeer, venueBeer);
        }
        if (resp.analysis && resp.analysis.beerhive) {
          const analysis = this.analysisTable.readInstance(resp.analysis.beerhive);
          // add the analysis if not yet present, replace if already present: it may be more recent
          if (this.analysisTable.has(resp.analysis.beerhive.id)) {
            this.analysisTable.remove(this.analysisTable.get(resp.analysis.beerhive.id));
          }
          this.analysisTable.add(analysis);
          venueBeer.setAnalysis(analysis);
        }
        if (resp.similarbeers && typeof resp.similarbeers.forEach === 'function') {
          resp.similarbeers.forEach((similarBeersObject: any) => {
            const similarBeerId = Cleaner.parseId(similarBeersObject.id);
            if (similarBeerId === null || !this.beerTable.has(similarBeerId)) {
              console.warn(`Problem while reading similar beer data for venue beer of "${beer.getName()}": ` +
                `got beer id '${similarBeerId}' that is not present in the beer table. Ignoring.`);
              return;
            }
            const similarBeer = this.beerTable.get(similarBeerId);
            this.venue.addSimilarVenueBeer(similarBeer, venueBeer);
          });
        }
      }
      return venueBeer;
    });
  }
  /** @inheritDoc
   *
   * @throws Got a null beer id while reading venue beer.
   */
  public applyRawData(data: any, instance: VenueBeer): void {
    // Base information
    instance.setId(Cleaner.parseId(data.id));
    instance.setCreationTime(Cleaner.parseDate(data.creationTs));
    instance.setLastUpdate(Cleaner.parseDate(data.updateTs));

    // Relations: beer, analysis, venue
    const beerId = Cleaner.parseId(data.beerid);
    if (beerId === null) {
      throw new Error('Got a null beer id while reading venue beer.');
    }
    instance.setBeer(this.beerTable.get(beerId));
    const analysisId = Cleaner.parseId(data.analysis?.id);
    if (analysisId !== null) {
      if (!this.analysisTable.has(analysisId)) {
        console.warn(`Analysis id ${analysisId} of venue beer ${instance.getBeer().getName()} not found in table. Ignoring.`);
      } else {
        instance.setAnalysis(this.analysisTable.get(analysisId));
      }
    } else {
      instance.setAnalysis(null);
    }
    const venueId = Cleaner.parseId(data.venueid);
    if (venueId !== this.venue.getId()) {
      console.warn(`Got venue beer from other venue! Expected: ${this.venue.getId()}. Actual: ${venueId}.`);
    }

    // Properties
    Cleaner.safeApply(instance, instance.setBottleImageUrl, data.bottleImg);
    Cleaner.safeApply(instance, instance.setWhetherIsLocal, data.local);
    Cleaner.safeApply2(instance, instance.setWhetherIsNew, data.new, (data.newEndDate ? data.newEndDate : new Date(Date.now() + 31*24*60*60*1000)));
    Cleaner.safeApply3(instance, instance.setWhetherIsPromo, data.promo, (data.promoStartDate ? data.promoStartDate : new Date()), (data.promoEndDate ? data.promoEndDate : new Date(Date.now() + 31*24*60*60*1000)));
    Cleaner.safeApply2(instance, instance.setWhetherIsTemporary, data.temporary, (data.temporaryEndDate ? data.temporaryEndDate : new Date(Date.now() + 31*24*60*60*1000)));
    instance.setWhetherDescriptionShouldBeAutoGenerated((('autoBeerDescription' in data) ? data.autoBeerDescription : (
      data.description ? Object.getOwnPropertyNames(data.description).length === 0 : true
    )));
    Cleaner.safeApply(instance, instance.setLocation, data.location);
    Cleaner.safeApply(instance, instance.setLocations, data.locations);

    // Servings
    instance.clearBeerServings();
    (data.servings as any[] || []).map((servingData) => BeerServing.read({
      volume: servingData.volume,
      volumeUnit: !servingData.volumeUnit ? Units.Centiliter.getName() : Unit.getUnitBySymbol(servingData.volumeUnit)?.getName(),
      casing: servingData.serveMethod,
      _isInPromotion: servingData.isPromo,
      promotionPrice: parseFloat(servingData.promoPrice),
      price: parseFloat(servingData.price)
    })).forEach((serving) => serving && instance.addBeerServing(serving));

    // Tags
    instance.clearBeerTags();
    (data.tags as string[] || []).forEach((rawTagId) => {
      const tagId = Cleaner.parseId(rawTagId);
      if (tagId === null) {
        console.warn(`Got null tag id of venue beer ${instance.getBeer().getName()}. Ignoring.`);
      } else {
        try {
          let tag = this.beerTagTable.get(tagId);
          instance.addBeerTag(tag);
        }
        catch(e) {
          console.warn(e);
        }
      }
    });

    // Description
    instance.getDescriptionMap().clear();
    Object.getOwnPropertyNames(data.description || {}).map((lang) => instance.setDescription(lang, data.description[lang]));

    // Trivia
    instance.clearTrivia();
    Object.getOwnPropertyNames(data.trivia || {}).map((lang) => instance.setTrivia(lang, data.trivia[lang]));

    // External reference
    Cleaner.safeApply(instance, instance.setExternalReference, data.externalReference);
  }
  /**
   * @inheritDoc
   */
  protected fetchRawData(): Promise<void> {
    return super.fetchRawData().then(() => this.getRawSimilarBeersData().then((data) => {
      this.rawSimilarBeerData = data;
    }));
  }
  /**
   * @inheritDoc
   */
  protected loadRawData() {
    if (this.rawSimilarBeerData === null) {
      throw new Error('Must first fetch raw data before loading');
    }
    super.loadRawData();

    // Load similar beer data.
    console.log('Loading similar beer data.');
    console.log(this.rawSimilarBeerData);

    // first clear all similar beer data
    this.venue.clearSimilarVenueBeers();

    // Now fill them up again
    let nbrOfWarnings: number = 0;
    this.rawSimilarBeerData.forEach((data: {id: string, similars: string[]}) => {
      const beerId1 = Cleaner.parseId(data.id);
      if (beerId1 === null || !this.beerTable.has(beerId1)) {
        if (nbrOfWarnings <= 10) {
          console.warn(`Problem while reading similar beer data for venue "${this.venue.getName()}": ` +
          `got beer id '${beerId1}' that is not present in the beer table. Ignoring.`);
        }
        nbrOfWarnings++;
        return;
      }
      const beer1 = this.beerTable.get(beerId1);
      data.similars.forEach((rawBeerId2) => {
        const beerId2 = Cleaner.parseId(rawBeerId2);
        if (beerId2 === null || !this.beerTable.has(beerId2)) {
          if (nbrOfWarnings <= 10) {
            console.warn(`Problem while reading similar beer data for venue "${this.venue.getName()}": ` +
            `got beer id '${beerId2}' that is not present in the beer table. Ignoring.`);
          }
          nbrOfWarnings++;
          return;
        }
        const beer2 = this.beerTable.get(beerId2);
        const venueBeer = beer2.getVenueBeer(this.venue);
        if (venueBeer === null) {
          if (nbrOfWarnings <= 10) {
            console.warn(`Problem while reading similar beer data for venue '${this.venue.getName()}': ` +
            `got a similar beer that is not a venue beer. Ignoring.`, beer2);
          }
          nbrOfWarnings++;
          return;
        }
        this.venue.addSimilarVenueBeer(beer1, venueBeer);
      });
    });
  }

  private getRawAnalysisData(): Promise<any[]> {
    return this.getRawData().then((data) => {
      return data.filter((venueBeerData) => venueBeerData.analysis).map((venueBeerData) => venueBeerData.analysis);
    });
  }
  /**
   * @inheritDoc
   * @throws This venue has a null id!
   */
  protected getRawData(): Promise<any[]> {
    if (!this.rawDataPromise) {
      const venueId = this.venue.getId();
      if (venueId === null) {
        throw new Error('This venue has a null id!');
      }
      this.rawDataPromise = this.venueFacadeService.getRawVenueBeerData(this.venue);
//      this.rawDataPromise = this.http.get<any>(this.refdata.getVenueBeerUrl(venueId)).toPromise();
    }
    return this.rawDataPromise.then((data) => data.rows);
  }
  /**
   * Get the raw similar beer data from the back-end.
   *
   * @returns A promise containing a list of the raw similar beer data from the back-end.
   */
  protected getRawSimilarBeersData(): Promise<any[]> {
    if (!this.rawDataPromise) {
      const venueId = this.venue.getId();
      if (venueId === null) {
        throw new Error('This venue has a null id!');
      }
      this.rawDataPromise = this.http.get<any>(this.refdata.getVenueBeerUrl(venueId)).toPromise();
    }
    return this.rawDataPromise.then((data) => data.similarBeers);
  }
}
