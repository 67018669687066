import { OrderService } from './../order/order.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, NgZone } from '@angular/core';
import { VenueService } from '../venue/venue.service';
import { BehaviorSubject } from 'rxjs';

/**
 * A service that navigates to the home page and resets the orders and the selected language when the user is idle.
 */
@Injectable({
  providedIn: 'root'
})
export class IdleTrackerService {
  private timer: NodeJS.Timeout | null = null;

  private eventsArray = [
    'keypress',
    'keydown',
    'click',
    'contextmenu',
    'dblclick',
    'mousemove',
    'scroll',
    'touchmove',
    'touchstart',
    'msgesturestart',
    'mousewheel'
  ];

  public static $cleanSituation = new BehaviorSubject<boolean>(false);

  public constructor(
    private venueService: VenueService,
    private translateService: TranslateService,
    private orderService: OrderService,
    private router: Router,
    private zone: NgZone
  ) {}

  /**
   * If the currently selected venue has a {@link Venue.getAutoHomeTime | auto home time}, start tracking the idleness of the user.
   */
  public trackIdleness(): void {
    if (this.venueService.getSelectedVenue()?.getAutoHomeTime()) {
      // Check activeness events outside the Angular zone to prevent
      // the Angular change detection to run every time the mouse moves.
      this.zone.runOutsideAngular(() => {
        this.runTimer();
        this.eventsArray.forEach((event) => {
          window.addEventListener(event, () => this.runTimer());
        });
      });
    }
  }

  /**
   * Navigate to the home page and reset the orders and (optionally) the selected language.
   *
   * @param resetLanguage  Whether the selected language should be reset to the default language.
   */
  public executeHome(resetToCleanStatus: boolean = true): void {
    IdleTrackerService.$cleanSituation.next(resetToCleanStatus);
    this.orderService.clearOrders();
    const venue = this.venueService.getSelectedVenue(true);
    this.router.navigate([venue.getUrlPath()]);
    if (resetToCleanStatus) {
      this.translateService.use(venue.getDefaultLanguage());
    }
  }

  private runTimer(): void {
    // Note that this function is still run outside of the Angular zone!
    if (this.timer !== null) {
      clearInterval(this.timer);
    }
    this.timer = null;
    const time = this.venueService.getSelectedVenue(true).getAutoHomeTime();
    if (time !== null) {
      this.timer = setInterval(() => {
        if (this.timer !== null) {
          clearInterval(this.timer);
        }
        this.timer = null;
        // Re-enter the Angular zone so that Angular's change detection will run.
        this.zone.run(() => {
          this.executeHome();
        });
      }, time * 1000);
    }
  }
}
