import { Component, OnInit } from '@angular/core';
import { UrlService } from '../../services/url/url.service';

@Component({
  selector: 'app-powered-by',
  templateUrl: './powered-by.component.html',
  styleUrls: ['./powered-by.component.scss'],
})
export class PoweredByComponent implements OnInit {

  constructor(public urlService: UrlService) { }

  ngOnInit() {}

}
