/**
 * An enum representing the days of the week.
 */
export enum Weekday {
    Monday = 'MO',
    Tuesday = 'TU',
    Wednesday = 'WE',
    Thursday = 'TH',
    Friday = 'FR',
    Saturday = 'SA',
    Sunday = 'SU'
}

/**
 * A list of all weekdays.
 */
export const weekdays: readonly [Weekday, Weekday, Weekday, Weekday, Weekday, Weekday, Weekday] = [
    Weekday.Monday,
    Weekday.Tuesday,
    Weekday.Wednesday,
    Weekday.Thursday,
    Weekday.Friday,
    Weekday.Saturday,
    Weekday.Sunday
];

/**
 * Create a record of weekdays.
 */
export function createWeekdayRecord<T>(mapF: (d: Weekday) => T): Record<Weekday, T> {
    const record: any = {};
    weekdays.forEach((day) => record[day] = mapF(day));
    return record;
}
/**
 * Map a weekday record to another one.
 */
export function mapWeekdayRecord<T, U>(record: Record<Weekday, T>, mapF: (v: T, d: Weekday) => U): Record<Weekday, U> {
    const result: any = {};
    weekdays.forEach((day) => result[day] = mapF(record[day], day));
    return result;
}
