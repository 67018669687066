import { weekdays } from './../../models/weekday';
import { OpeningTimes, OpeningDay } from './../../models/opening-time';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';
import { RefdataService } from '../../services/refdata/refdata.service';
import { LoggerService } from '../../services/logger/logger.service';
import { Venue } from '../../models/persistency/persistent-models/venue';
import { Listener } from '../../models/listener';


@Component({
  selector: 'app-venue-hours',
  templateUrl: './venue-hours.component.html',
  styleUrls: ['./venue-hours.component.scss']
})
export class VenueHoursComponent extends Listener implements OnInit {
  @Input()
  public venue!: Venue;

  public openingRepresentations: {
    days: string,
    times: string
  }[] = [];

  public constructor(
    public translateService: TranslateService,
    private logger: LoggerService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subscribe(this.translateService.onLangChange, () => this.initDaysAndTimes());
    this.subscribe(this.venue.getOpeningTimes(), () => this.initDaysAndTimes());

    this.logger.info('venue-hours.component', 'ngOnInit called with ' + (this.venue ? 'existing' : 'empty' ) + ' venue');
    this.initDaysAndTimes();
  }

  private initDaysAndTimes(): void {
    this.openingRepresentations = [];
    if (this.venue.hasOpeningTimes()) {
      // For each set of time ranges, find the matching days.
      const timesToDays: {time: OpeningDay, days: string[]}[] = [];
      const closedDays: string[] = [];
      this.venue.getOpeningTimes().forEach((openingDay, dayStr) => {
        if (openingDay.times.length > 2) {
          console.warn('Opening times at ' + dayStr + ' contains more than two time ranges. Adding to closed.');
          closedDays.push(dayStr);
        } else if (!openingDay.isOpen() || openingDay.times.length === 0) {
          closedDays.push(dayStr);
        } else {
          const existing = timesToDays.find((timeToDays) => timeToDays.time.equals(openingDay));
          if (existing) {
            existing.days.push(dayStr);
          } else {
            timesToDays.push({time: openingDay, days: [dayStr]});
          }
        }
      });

      // Convert to representation.
      timesToDays.forEach((timeToDay) => {
        this.openingRepresentations.push({
          days: this.getDays(timeToDay.days),
          times: this.getTimes(timeToDay.time)
        });
      });
      if (closedDays.length > 0) {
        this.openingRepresentations.push({
          days: this.getDays(closedDays),
          times: this.getTranslation('MENU.CLOSED')
        });
      }
    } else {
      this.openingRepresentations = [];
    }
  }

  private getDays(days: string[]): string {
    const results = [];

    let rangeStart: string | null = null;
    let rangeEnd: string | null = null;
    let nrOfDays = 0;
    for (const day of weekdays) {
      if (days.includes(day)) {
        if (nrOfDays === 0) {
          rangeStart = day;
        }
        rangeEnd = day;
        nrOfDays++;
      } else {
        if (nrOfDays !== 0) {
          let result = this.getTranslation('MENU.' + rangeStart!.toUpperCase());
          if (nrOfDays === 2) {
            result += ', ';
          } else if (nrOfDays > 2) {
            result += ' - ';
          }
          if (nrOfDays !== 1) {
            result += this.getTranslation('MENU.' + rangeEnd!.toUpperCase());
          }
          results.push(result);
        }
        nrOfDays = 0;
      }
    }
    if (nrOfDays !== 0) {
      let result = this.getTranslation('MENU.' + rangeStart!.toUpperCase());
      if (nrOfDays == 2) {
        result += ', ';
      } else if (nrOfDays > 2) {
        result += ' - ';
      }
      result += (nrOfDays >= 2 ? this.getTranslation('MENU.' + rangeEnd!.toUpperCase()) : '');
      results.push(result);
    }

    return results.join(', ');
  }

  private getTimes(times: OpeningDay): string {
    // filter out entries where from and to time are equal
    return times.times.filter((time) => !(time.from.h == time.to.h && time.from.m == time.to.m)).map((time) =>
      time.from.h.toString().padStart(2, '0') +
      this.getTranslation('MENU.H') +
      time.from.m.toString().padStart(2, '0') +
      ' - ' +
      time.to.h.toString().padStart(2, '0') +
      this.getTranslation('MENU.H') +
      time.to.m.toString().padStart(2, '0')
    ).join(', ');
  }
  private getTranslation(key: string): string {
    return this.translateService.instant(key);
  }
}
