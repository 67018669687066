import { Cleaner } from '../../cleaner';
import { BeerTag } from './beer-tag';
import { ModelTable } from '../model-table';
import { Venue } from './venue';

/**
 * A {@link ModelTable} of {@link BeerTag}s responsible for reading, saving, deleting and loading instances.
 */
export class BeerTagTable extends ModelTable<BeerTag> {
    private rawTagData: any[] | null = null;
    public constructor(private venue: Venue) {
        super();
    }

    /**
     * Save the given {@link BeerTag} to the back-end, and adds it to this table if not already present.
     * Note that this will also save the venue that this table belongs to.
     *
     * @param tag  The {@link BeerTag} instance to save.
     * @returns A promise that resolves when the given instance is saved.
     */
    public saveInstance(tag: BeerTag): Promise<void> {
        return super.saveInstance(tag).then(() => {
            this.venue.makeDirty();
            return this.venue.save();
        });
    }
    /**
     * Delete the given {@link BeerTag} from the back-end, and removes it from this table. The tag will also be
     * removed from any {@link VenueBeer} that have it. Note that this will also save the venue
     * that this table belongs to, together with all venue beers that have the given tag.
     *
     * @param tag  The {@link BeerTag} instance to delete.
     * @returns A promise that resolves when the given instance is deleted.
     */
    public deleteInstance(tag: BeerTag): Promise<void> {
        const vbs = this.venue.venueBeerTable.findAll((vb) => vb.hasBeerTag(tag));
        vbs.forEach((vb) => vb.removeBeerTag(tag));
        return super.deleteInstance(tag).then(() => {
            this.venue.makeDirty();
            const savePromises = Array.from(vbs, (vb) => vb.save());
            savePromises.push(this.venue.save());
            return Promise.all(savePromises).then(() => {});
        });
    }

    private generateFreeId(): string {
        let freeId = 0;
        while (this.has(freeId.toString())) {
            freeId++;
        }
        return freeId.toString();
    }

    /** @inheritDoc */
    public serializeInstance(instance: BeerTag) {
        const labels: {[key: string]: string} = {};
        instance.getLabelMap().forEach((text: string, lang: string) => labels[lang] = text);
        return {
            id: instance.getId(),
            creationTs: instance.getCreationTime(),
            updateTs: instance.getLastUpdate(),
            type: instance.getType(),
            langlabels: labels
        };
    }

    /** @inheritDoc */
    public createInstance(): BeerTag {
        return new BeerTag(this.venue, this, this.generateFreeId());
    }
    /** @inheritDoc */
    public applyRawData(data: any, instance: BeerTag): void {
        instance.setId(Cleaner.parseId(data.id));
        instance.setCreationTime(Cleaner.parseDate(data.creationTs));
        instance.setLastUpdate(Cleaner.parseDate(data.updateTs));

        Cleaner.safeApply(instance, instance.setType, data.type);

        instance.getLabelMap().clear();
        Object.getOwnPropertyNames(data.langlabels).forEach((lang) => instance.setLabel(lang, data.langlabels[lang]));
    }

    /**
     * Set the raw data of this table.
     *
     * @param tagData  The raw data representing a list of beer tags.
     */
    public setRawData(tagData: any[]): void {
        this.rawTagData = tagData;
    }
    /**
     * Get the raw data representing a list of beer tags.
     *
     * @throws Raw data of beer-tags must first be set using {@link setRawData} before loading this table.
     */
    protected getRawData(): Promise<any[]> {
        if (this.rawTagData === null) {
            throw new Error('Raw data of beer-tags must first be set before loading this table.');
        }
        return Promise.resolve(this.rawTagData);
    }

    public cloneTable(): BeerTagTable {
        let result: BeerTagTable = new BeerTagTable(this.venue);
        result.rawTagData = this.rawTagData;
        return result;
    }
}
