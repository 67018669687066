import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Listener } from '../../models/listener';
import { LanguageMap } from '../../models/language-map';

@Component({
  selector: 'app-venue-about',
  templateUrl: './venue-about.component.html',
  styleUrls: ['./venue-about.component.scss']
})
export class VenueAboutComponent extends Listener implements OnInit {
  @Input()
  public showTitle = false;

  @Input()
  public abouts!: LanguageMap;

  public about = '';

  constructor(
    public translateService: TranslateService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.updateAbout();
    this.subscribe(this.translateService.onLangChange, () => this.updateAbout());
    this.subscribe(this.abouts, () => this.updateAbout());
  }
  private updateAbout(): void {
    this.about = this.abouts.get(this.translateService.currentLang) || '';
  }

}
