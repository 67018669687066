import { Injectable } from '@angular/core';
import { RefdataService } from '../refdata/refdata.service';
import { HttpClient } from '@angular/common/http';
import { LocalPersisterService } from './../../services/local-persister/local-persister.service';
import { Venue } from '../../models/persistency/persistent-models/venue';


class DataFetchContainer {
  public rawData: any = null;
  public fetchPromise: Promise<void> | null = null;
  public lastModified: number = 0;
  public lastModifiedGetterUrl: string = '';
  public dataGetterUrl: string = '';
}
class VenueFacadeContainers {
  public venue: DataFetchContainer = new DataFetchContainer();
  public venuebeer: DataFetchContainer = new DataFetchContainer();

  public get(dataType: string): DataFetchContainer {
    switch (dataType) {
      case 'venue': return this.venue;
      case 'venuebeer': return this.venuebeer;
      default:
        console.log('VenueFacadeContainers.get: unexpected dataType ' + dataType);
        throw new Error('VenueFacadeContainers.get: unexpected dataType ' + dataType);
    }
  }
}
@Injectable({
  providedIn: 'root'
})
export class VenueFacadeService {
  private fetchDataContext: VenueFacadeContainers = new VenueFacadeContainers();

  public constructor(private http: HttpClient,
                     private refdata: RefdataService,
                     private localPersister: LocalPersisterService) {
  }
  private initContainers(venue: Venue | null) {
    let selectedVenue: Venue | null = venue
    if (selectedVenue === null) {
      console.log("VenueFacadeService.initContainers: no venue selected");
      // throw new Error('trying to fetch venue data in context without venue');
      return false;
    }
    let venueId: string = selectedVenue!.getId()!;

    this.fetchDataContext['venue'].lastModifiedGetterUrl = this.refdata.getVenueUrlMappingLastModifiedUrl(venueId);
    this.fetchDataContext['venue'].dataGetterUrl = this.refdata.getVenueUrlMappingUrl(venueId);

    this.fetchDataContext['venuebeer'].lastModifiedGetterUrl = this.refdata.getVenueBeerLastModifiedUrl(venueId);
    this.fetchDataContext['venuebeer'].dataGetterUrl = this.refdata.getVenueBeerUrl(venueId);

    return true;
  }
  private async fetchData(venue: Venue | null, dataType: string, forceReload: Boolean = false): Promise<void> {

    if (this.initContainers(venue)) {

      let context: DataFetchContainer = this.fetchDataContext.get(dataType); 

      let lastModifiedOnServer = 0;

      let promise1 = this.http.head<any>(context.lastModifiedGetterUrl,{ observe: "response"}).toPromise();
      let promise2 = this.localPersister.get(dataType + '-data-last-loaded-modified');
      let promise3 = this.localPersister.get(dataType + '-data');
      let promise4 = this.localPersister.get(dataType + '-data-last-loaded-modified-venue-id');

      let promiseAll = Promise.all([promise1, promise2, promise3, promise4]);
      await promiseAll.then((resultArray) => {
        let lastModifiedHeader: string | null = resultArray[0].headers.get("Last-Modified");
        if (lastModifiedHeader !== null) {
          lastModifiedOnServer = Date.parse(lastModifiedHeader);
        }
        let lastLoadedModifiedDate = resultArray[1];
        let dataFromLocalPersistence = resultArray[2];
        let lastLoadedModifiedDateVenueId = resultArray[3];

        console.log('VenueFacadeService.fetchData: forceReload = ' + forceReload);
        console.log('VenueFacadeService.fetchData: lastModifiedOnServer   = ' + lastModifiedOnServer);
        console.log('VenueFacadeService.fetchData: lastLoadedModifiedDate = ' + lastLoadedModifiedDate);
        console.log('VenueFacadeService.fetchData: lastLoadedModifiedDateVenueId = ' + lastLoadedModifiedDateVenueId);

        if (forceReload || (venue?.getId() != lastLoadedModifiedDateVenueId) || ((lastModifiedOnServer > lastLoadedModifiedDate) || !dataFromLocalPersistence || !lastLoadedModifiedDate)) {
          // we MUST reload, unless another reload is ongoing: we check therefore if the promise is not null
          console.log('VenueFacadeService.fetchData: we MUST reload, unless another reload is ongoing');
          if (context.fetchPromise === null) {
            console.log('VenueFacadeService.fetchData: we MUST reload, and no another reload is ongoing');
            context.fetchPromise = this.http.get<any>(context.dataGetterUrl).toPromise().then((result) => {
              console.log('VenueFacadeService.fetchData: we have retrieved the data');
              context.rawData = result;
              context.lastModified = lastModifiedOnServer;
              this.localPersister.set(dataType + '-data-last-loaded-modified', lastModifiedOnServer);
              this.localPersister.set(dataType + '-data-last-loaded-modified-venue-id', venue?.getId());
              this.localPersister.set(dataType + '-data', result);

              // data is loaded, soset the promise to zero to ensure reloading at next update
              context.fetchPromise = null;
            }).catch((err) => console.log('VenueFacadeService.fetchData: error fetching data: ' + err));
          }
          return context.fetchPromise;
        }
        else {
          // no changes on the server and we have the data locally available
          console.log('VenueFacadeService.fetchData: no changes on the server and we have the data locally available');
          if (context.rawData == null) {
            context.rawData = dataFromLocalPersistence;
            context.lastModified = lastLoadedModifiedDate;
          }
          return Promise.resolve();
        }
      });
    }
  }
  private async checkDataModification(venue: Venue | null, dataType: string): Promise<Boolean> {

    if (this.initContainers(venue)) {

      let context: DataFetchContainer = this.fetchDataContext.get(dataType); 

      let lastModifiedOnServer = 0;

      let promise1 = this.http.head<any>(context.lastModifiedGetterUrl,{ observe: "response"}).toPromise();
      let promise2 = this.localPersister.get(dataType + '-data-last-loaded-modified');
      let promise3 = this.localPersister.get(dataType + '-data-last-loaded-modified-venue-id');

      let promiseAll = Promise.all([promise1, promise2,promise3]);
      let isModified : Boolean = await promiseAll.then((resultArray) => {
        let lastModifiedHeader: string | null = resultArray[0].headers.get("Last-Modified");
        if (lastModifiedHeader !== null) {
          lastModifiedOnServer = Date.parse(lastModifiedHeader);
        }
        let lastLoadedModifiedDate = resultArray[1];
        let lastLoadedModifiedDateVenueId = resultArray[2];

        console.log('VenueFacadeService.checkDataModification: lastModifiedOnServer   = ' + lastModifiedOnServer);
        console.log('VenueFacadeService.checkDataModification: lastLoadedModifiedDate = ' + lastLoadedModifiedDate);
        console.log('VenueFacadeService.checkDataModification: lastLoadedModifiedDateVenueId = ' + lastLoadedModifiedDateVenueId);

        if ((venue?.getId() != lastLoadedModifiedDateVenueId) || (lastModifiedOnServer > lastLoadedModifiedDate) || !lastLoadedModifiedDate) {
          // we MUST reload, unless another reload is ongoing: we check therefore if the promise is not null
          console.log('VenueFacadeService.checkDataModification: data is modified, so we should reload');
          return Promise.resolve(true);
        }
        else {
          console.log('VenueFacadeService.checkDataModification: data is NOT modified, so we should not reload');
          return Promise.resolve(false);
        }
      });
      return Promise.resolve(isModified);
    }
    else { // no venue selected, so no modification to be reacted to
      console.log('VenueFacadeService.checkDataModification: initialize returned false: no venue selected, so no reload needed');
      return Promise.resolve(false);
    }
    console.log('VenueFacadeService.checkDataModification: should we ever end up here?');
    return Promise.resolve(false);
  }
  public getRawVenueData(venue: Venue | null, forceReload: Boolean = false): Promise<any> {
    let promise: Promise<void> = this.fetchData(venue, 'venue', forceReload);
    if (promise) {
      return promise.then(() => {
        return this.fetchDataContext.get('venue').rawData;
      });
    }
    return Promise.resolve();
  }
  public getRawVenueBeerData(venue: Venue | null, forceReload: Boolean = false): Promise<any> {
    let promise: Promise<void> = this.fetchData(venue, 'venuebeer', forceReload);
    if (promise) {
      return promise.then(() => {
        return this.fetchDataContext.get('venuebeer').rawData;
      });
    }
    return Promise.resolve();
  }
  public checkVenueDataModification(venue: Venue | null): Promise<Boolean> {
    return this.checkDataModification(venue, 'venue');
  }
  public checkVenueBeerDataModification(venue: Venue | null): Promise<any> {
    return this.checkDataModification(venue, 'venuebeer');
  }
}
