export const modelUrls = {
    beersandbreweries: {
        'base': 'apiBaseUrl',
        'HEAD': 'pub_manager/beer/beersandbreweries',
        'GET': 'pub_manager/beer/beersandbreweries'
    },
    venue: {
        'base': 'apiBaseUrl',
        'HEAD': 'pub_manager/pub/getbyurlname',
        'GET': 'pub_manager/pub/getbyurlname',
        'CREATE': 'pub_manager/pub/create',
        'UPDATE': 'pub_manager/pub/update',
        'DELETE': 'pub_manager/pub/delete'
    },
    venuebyid: {
        'base': 'apiBaseUrl',
        'HEAD': 'pub_manager/pub/get/',
        'GET': 'pub_manager/pub/get/',
        'POST': 'pub_manager/pub/get',
    },
    venuebyurl: {
        'base': 'apiBaseUrl',
        'HEAD': 'pub_manager/pub/getbyurlname/',
        'GET': 'pub_manager/pub/getbyurlname/',
        'POST': 'pub_manager/pub/getbyurlname',
    },
    adminvenue: {
        'base': 'apiBaseUrl',
        'GET': 'pub_manager/pub/list?archived=0&ownerUserId=',
        'CREATE': 'pub_manager/pub/create',
        'UPDATE': 'pub_manager/pub/update',
        'DELETE': 'pub_manager/pub/delete'
    },
    venuebeer: {
        'base': 'apiBaseUrl',
        'HEAD': 'pub_manager/pub/listbeers/',
        'GET': 'pub_manager/pub/listbeers/',
        'POST': 'pub_manager/pubbeer/crud',
        'LIGHT': 'pub_manager/pubbeer/shelf/light'
    },
    venuebeerprofile: {
        'base': 'lambdaApiBaseUrl',
        'GET': 'profile'
    },
    userprofile: {
        'base': 'apiBaseUrl',
        'UPDATE': 'admin/user/update',
        'GET': 'admin/user/get'
    },
    beer: {
        'base': 'lambdaApiBaseUrl',
        'CREATE': 'addbeer'
    },
    brewery: {
        'base': 'lambdaApiBaseUrl',
        'CREATE': 'addbrewery'
    }
} as const;
