import { Brewery } from './brewery';
import { PersistentModel } from '../persistent-model';
import { VenueBeer } from './venue-beer';
import { ModelTable } from '../model-table';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { Venue } from './venue';
import { Relation } from '../relation';
import { BHError } from '../../utilities';

/**
 * A {@link PersistentModel} representing a beer.
 */
export class Beer extends PersistentModel {
    private static BeerLogoPrefix = environment.logoBaseUrl + 'beer/logos/';

    private name: string;
    private ABV: number | null; // Alcohol By Volume
    private EBC: number | null; // European Brewery Convention: a number for the color of the beer.
    private IBU: number | null; // International Bitterness Units
    private brewery: Relation<Beer, Brewery>;
    private logoUrl: string | null;

    private tenant: string | null = null;
    private tenantId: string | null = null;

    /**
     * Create a new beer with `null` fields.
     *
     * @param translateService  A translate service to translate the beer description.
     * @param table  The table this beer belongs to.
     * @param breweryTable  The brewery table containing possible breweries of beers.
     */
    public constructor(private translateService: TranslateService,
            table: ModelTable<Beer>, breweryTable: ModelTable<Brewery>) {
        super(table);

        this.name = '';
        this.ABV = null;
        this.EBC = null;
        this.IBU = null;
        this.brewery = new Relation<Beer, Brewery>(this, breweryTable);
        this.logoUrl = null;

        this.tenant = null;
        this.tenantId = null;
    }

    /**
     * Return the name of this beer.
     *
     * @returns The name of this beer.
     */
    public getName(): string {
        return this.name;
    }
    /**
     * Set the name of this beer.
     *
     * @param name The new name for this beer.
     */
    public setName(name: string): void {
        this.name = name;
        this.makeDirty();
    }
    /**
     * Get the alcohol by volume of this beer.
     *
     * @returns The alcohol by volume of this beer.
     */
    public getABV(): number | null {
        return this.ABV;
    }
    /**
     * Set the alcohol by volume of this beer.
     *
     * @param ABV  The new alcohol by volume for this beer.
     */
    public setABV(ABV: number | null): void {
        this.ABV = ABV;
        this.makeDirty();
    }
    /**
     * Return the European brewery convention value of this beer, representing its color.
     *
     * @returns  The European brewery convention value of this beer, representing its color.
     */
    public getEBC(): number | null {
        return this.EBC;
    }
    /**
     * Set the European brewery convention value of this beer.
     *
     * @param EBC  The new European brewery convention value for this beer.
     */
    public setEBC(EBC: number | null): void {
        this.EBC = EBC;
        this.makeDirty();
    }
    /**
     * Get the international bitterness units of this beer.
     *
     * @returns The international bitterness units of this beer.
     */
    public getIBU(): number | null {
        return this.IBU;
    }
    /**
     * Set the international bitterness units of this beer.
     *
     * @param IBU  The new international bitterness units for this beer.
     */
    public setIBU(IBU: number | null): void {
        this.IBU = IBU;
        this.makeDirty();
    }
    /**
     * Get the tenant ho created of this beer.
     *
     * @returns The tenant who created this beer.
     */
     public getTenant(): string | null {
        return this.tenant;
    }
    /**
     * Set the tenant who creates this beer.
     *
     * @param tenant  The tenant who creates this beer.
     */
    public setTenant(tenant: string | null): void {
        this.tenant = tenant;
        this.makeDirty();
    }
    /**
     * Get the id of the tenant ho created of this beer.
     *
     * @returns The id of the tenant who created this beer.
     */
     public getTenantId(): string | null {
        return this.tenantId;
    }
    /**
     * Set the id of the tenant who creates this beer.
     *
     * @param tenantId  The id of the tenant who creates this beer.
     */
    public setTenantId(tenantId: string | null): void {
        this.tenantId = tenantId;
        this.makeDirty();
    }
    /**
     * Get the {@link Brewery} of this beer.
     *
     * @returns The {@link Brewery} of this beer.
     */
    public getBrewery(): Brewery | null {
        return this.brewery.get();
    }
    /**
     * Set the {@link Brewery} of this beer.
     *
     * @param brewery  The new {@link Brewery} for this beer.
     */
    public setBrewery(brewery: Brewery): void {
        this.brewery.set(brewery);
    }
    /**
     * Get the raw url to the logo of this beer, or `null` if no logo is present.
     *
     * @returns The raw url to the logo of this beer, or `null` if no logo is present.
     */
     public getRawLogoUrl(): string | null {
        if (this.logoUrl) {
            return this.logoUrl;
        }
        return null;
    }
    /**
     * Get the full url to the logo of this beer, or `null` if no logo is present.
     *
     * @returns The full url to the logo of this beer, or `null` if no logo is present.
     */
    public getPrefixedLogoUrl(): string | null {
        if (this.logoUrl) {
            return Beer.BeerLogoPrefix + this.logoUrl;
        }
        return null;
    }
    /**
     * Set the relative path of the logo of this beer.
     *
     * @param logoUrl  The new relative path to the logo of this beer.
     */
    public setLogoUrl(logoUrl: string | null): void {
        this.logoUrl = logoUrl;
        this.makeDirty();
    }
    /**
     * Get the {@link VenueBeer} corresponding to this beer, or `null` if no was found.
     *
     * @param venue  The venue containing the {@link VenueBeer}s to look for.
     * @returns The {@link VenueBeer} corresponding to this beer, or `null` if no was found.
     */
    public getVenueBeer(venue: Venue): VenueBeer | null {
        return venue.venueBeerTable.getVenueBeer(this);
    }
    /**
     * Get the description of this beer. If a {@link VenueBeer} corresponding to this beer is present in
     * the given venue, the description of the venue beer is used.
     *
     * @param venue  The venue containing the {@link VenueBeer}s to look for.
     * @param language  The preferred language for the description.
     * @returns The description of the beer in the given language. If no {@link VenueBeer} was found, returns
     * an explanation corresponding to `CARD.NO_DESCRIPTION_AVAILABLE_YET`.
     */
    public getDescription(venue: Venue, language: string): string {
        const venueBeer = this.getVenueBeer(venue);
        if (venueBeer) {
            return venueBeer.getDescription(language);
        }
        return this.translateService.instant('CARD.NO_DESCRIPTION_AVAILABLE_YET');
    }
    /**
     * Get the full url to the logo of this beer with a fallback if no logo is present.
     *
     * @returns The full url to the logo of this beer, or if not present the full url to the logo of the brewery of this beer,
     * or if not present the full url to an icon representing a beer glass.
     */
    public getLogoUrlWithFallback(): string {
        return this.getPrefixedLogoUrl() ||
            this.getBrewery()?.getPrefixedLogoUrl() ||
            'assets/svgs/icon_beerglass.svg';
    }
    /**
     * Returns whether this beer is available in the given {@link Venue}.
     *
     * @param venue  The venue containing the {@link VenueBeer}s to check for.
     * @returns `true` if there exists a {@link VenueBeer} in the given venue corresponding to this beer, otherwise `false`.
     */
    public isAvailable(venue: Venue): boolean {
        return this.getVenueBeer(venue) !== null;
    }
    /**
     * Get a set of {@link VenueBeer}s that are similar to this beer.
     *
     * @param venue  The {@link Venue} containing the {@link VenueBeer}s to check for.
     * @returns A set of {@link VenueBeer}s that are similar to this beer.
     */
    public getSimilarVenueBeers(venue: Venue): ReadonlySet<VenueBeer> {
        return venue.getSimilarVenueBeers(this);
    }
    /**
     * Check whether this beer has any similar {@link VenueBeer}s in the given {@link Venue}.
     *
     * @param venue  The {@link Venue} containing the {@link VenueBeer}s to check for.
     * @returns Whether this beer has any similar {@link VenueBeer}s in the given {@link Venue}.
     */
    public hasSimilarVenueBeers(venue: Venue): boolean {
        return venue.hasSimilarVenueBeers(this);
    }
    /**
     * Validates the instance
     * 
     * @returns array of errors, if no errors, then returns an empty array
     */
    public validate(): BHError[] {
        let result: BHError[] = [];

        if (!this.brewery || this.brewery == null) {
            result.push(new BHError("ADD_BEER.ERROR.BREWERY_EMPTY", []));
        }
        if (!this.name || this.name == null || this.name.length == 0) {
            result.push(new BHError("ADD_BEER.ERROR.NAME_EMPTY", []));
        }
        if (this.ABV && this.ABV !== null && this.ABV < 0) {
            result.push(new BHError("ADD_BEER.ERROR.NEGATIVE_ATTRIBUTE", { attributeName : this.translateService.instant("ADD_BEER.ABV") }));
        }
        if (this.IBU && this.IBU !== null && this.IBU! < 0) {
            result.push(new BHError("ADD_BEER.ERROR.NEGATIVE_ATTRIBUTE", { attributeName : this.translateService.instant("ADD_BEER.IBU") }));
        }
        if (this.EBC && this.EBC !== null && this.EBC! < 0) {
            result.push(new BHError("ADD_BEER.ERROR.NEGATIVE_ATTRIBUTE", { attributeName : this.translateService.instant("ADD_BEER.EBC") }));
        }
        return result;
    }
    
}
