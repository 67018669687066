import { Analysis } from './persistency/persistent-models/analysis';
import { Beer } from './persistency/persistent-models/beer';
import { Filter, VenueBeerFilter } from './filter';
import { Serializable } from './serializable';
import { VenueBeer } from './persistency/persistent-models/venue-beer';
import { Casing } from './beer-serving';
import { Injectable } from '@angular/core';
import { Venue } from './persistency/persistent-models/venue';
import { Hexa } from './utilities';

/**
 * A type representing a readonly tuple of six {@link Selector}s.
 */
export type Hexaselector = Readonly<Hexa<Selector>>;
/**
 * A class describing an icon that users can select to filter beers.
 */
export class Selector extends Filter implements Serializable {
    public static readonly empty = new Selector('empty', '', null, Filter.MatchAll, undefined, 'var(--ion-color-primary)');

    private labelKey: string;
    private translationKey: string;
    private iconName: string | null;
    private color: string;
    private textColor: string;
    public readonly filter: Filter;
    private useGradient: boolean;
    public constructor(labelKey: string, translationKey: string, iconName: string | null,
         filter: Filter, color: string = 'white', textColor: string = 'black', useGradient: boolean = false) {
        super();
        this.labelKey = labelKey;
        this.translationKey = translationKey;
        this.iconName = iconName;
        this.color = color;
        this.textColor = textColor;
        this.filter = filter;
        this.useGradient = useGradient;
    }

    /**
     * Get the unique label key of this selector.
     *
     * @returns The unique label key of this selector.
     */
    public getLabelKey(): string {
        return this.labelKey;
    }
    /**
     * Get the translation key representing the label of this selector.
     *
     * @returns The translation key of this selector, which can be used by the translate service to get a label
     * of this selector in a specific language.
     */
    public getTranslationKey(): string {
        return this.translationKey;
    }
    /**
     * Get the name of the icon of this selector.
     *
     * @returns A `name` that represents an icon with the path `` `assets/svgs/hexagons/icon_${name}.svg` ``, or `null`
     * if this selector has no icon.
     */
    public getIconName(): string | null {
        return this.iconName;
    }
    /**
     * Get the background color of this selector.
     *
     * @returns The background color of this selector.
     */
    public getColor(): string {
        return this.color;
    }
    /**
     * Get the gradient color of this selector.
     *
     * @returns A string representing a gradient of the color of this selector.
     */
    public getGradientColor(): string {
        return 'url(#gradient_' + this.color + ')';
    }
    /**
     * Get whether this selector should be represented with a gradient as background or not.
     *
     * @returns Whether this selector should be represented with a gradient as background or not.
     */
    public shouldGradientBeUsed(): boolean {
        return this.useGradient;
    }
    /**
     * Get the text color of the label of this selector.
     *
     * @returns The text color of the label of this selector.
     */
    public getTextColor(): string {
        return this.textColor;
    }

    /**
     * @inheritDoc
     */
    public matchBeer(beer: Beer, venue: Venue): boolean {
        return this.filter.matchBeer(beer, venue);
    }
    /**
     * @inheritDoc
     */
    public matchVenueBeer(venueBeer: VenueBeer): boolean {
        return this.filter.matchVenueBeer(venueBeer);
    }

    /**
     * @inheritDoc
     */
    public toJSON(): any {
        return this.labelKey;
    }
}

/**
 * An abstract class representing a specific type of {@link Selector}s, which keeps track of all selectors of its type.
 */
export abstract class SelectorType {
    private readonly selectorMap = new Map<string, Selector>();

    protected add(...selectors: Selector[]): void {
        selectors.forEach((selector) => this.selectorMap.set(selector.getLabelKey(), selector));
    }

    public getSelectorByLabelKey(labelKey: string): Selector | null {
        return this.selectorMap.get(labelKey) || null;
    }
    public getAll(): ReadonlySet<Selector> {
        return new Set<Selector>(this.selectorMap.values());
    }
}
abstract class HexaselectorType extends SelectorType {
    private readonly hexaselector: Hexaselector;
    public constructor(hexaselector: Hexaselector) {
        super();
        this.hexaselector = hexaselector;
        this.add(...hexaselector);
    }

    public getHexaselector(): Hexaselector {
        return this.hexaselector;
    }
}

/**
 * A {@link SelectorType} for miscellaneous selectors.
 */
@Injectable({
    providedIn: 'root'
})
export class OtherSelectors extends SelectorType {
    public constructor() {
        super();
        this.add( new Selector('tap', 'OTHER_CHARACTERISTICS.TAP', 'tap', VenueBeerFilter.CreateVenueBeerFilter(
                    (venueBeer) =>
                        venueBeer.getBeerServings().some((serving) => serving.getCasing() === Casing.Tap)))
                , new Selector('can', 'OTHER_CHARACTERISTICS.CAN', 'can', VenueBeerFilter.CreateVenueBeerFilter(
                    (venueBeer) =>
                        venueBeer.getBeerServings().some((serving) => serving.getCasing() === Casing.Can)))
                , new Selector('bottle', 'OTHER_CHARACTERISTICS.BOTTLE', 'bottle',
                    VenueBeerFilter.CreateVenueBeerFilter((venueBeer) =>
                        venueBeer.getBeerServings().some((serving) => serving.getCasing() === Casing.Bottle)))
                , new Selector('international', 'OTHER_CHARACTERISTICS.INTERNATIONAL', 'international',
                    Filter.CreateFilter((beer, venue) => {
                        const brewery = beer.getBrewery();
                        return brewery !== null && venue.getAddress().getCountry() !== brewery.getAddress().getCountry();
                    }))
                , new Selector('lowalcohol', 'OTHER_CHARACTERISTICS.LOWALCOHOL',
                    'lowalcohol', Filter.CreateFilter((beer, venue) => {
                        const abv = beer.getABV();
                        return abv === null || abv <= venue.getMaxLowABV();
                    }))
                , new Selector('promo', 'OTHER_CHARACTERISTICS.PROMO', 'promo', VenueBeerFilter.CreateVenueBeerFilter(
                    (venueBeer) =>
                        venueBeer.isPromo()))
                , new Selector('local', 'OTHER_CHARACTERISTICS.LOCAL', 'local', VenueBeerFilter.CreateVenueBeerFilter(
                    (venueBeer) =>
                        venueBeer.isLocal()))
                , new Selector('temporary', 'OTHER_CHARACTERISTICS.TEMPORARY', 'temporary',
                    VenueBeerFilter.CreateVenueBeerFilter((venueBeer) =>
                        venueBeer.isTemporary()))
                , new Selector('new', 'OTHER_CHARACTERISTICS.NEW', 'new', VenueBeerFilter.CreateVenueBeerFilter(
                    (venueBeer) =>
                        venueBeer.isNew()))

        );
    }
}

class AromaFilter extends VenueBeerFilter {
    public constructor(private getProp: (vb: VenueBeer) => number | null, private threshold: number) {
        super();
    }
    public matchVenueBeer(venueBeer: VenueBeer): boolean {
        const prop = this.getProp(venueBeer);
        return prop !== null &&
               prop >= this.threshold;
    }
}
/**
 * A {@link SelectorType} for aroma selectors.
 */
@Injectable({
    providedIn: 'root'
})
export class AromaSelectors extends HexaselectorType {
    private static readonly aromaThreshold = 2.5;
    private static readonly sourThreshold = 2.0;
    public constructor() {
        super(
            [ new Selector('sweet', 'AROMAS.SWEET', 'sweet', new AromaFilter(
                (vb) => vb.getBaseAroma('sweet') , AromaSelectors.aromaThreshold))
            , new Selector('sour', 'AROMAS.SOUR', 'sour', new AromaFilter(
                (vb) => vb.getBaseAroma('sour'), AromaSelectors.sourThreshold))
            , new Selector('bitter', 'AROMAS.BITTER', 'bitter', new AromaFilter(
                (vb) => vb.getBaseAroma('bitter'), AromaSelectors.aromaThreshold))
            , new Selector('hoppy', 'AROMAS.HOPPY', 'hoppy', new AromaFilter(
                (vb) => vb.getBaseAroma('hoppy'), AromaSelectors.aromaThreshold))
            , new Selector('fruity', 'AROMAS.FRUITY', 'fruity', new AromaFilter(
                (vb) => vb.getBaseAroma('fruity'), AromaSelectors.aromaThreshold))
            , new Selector('malty', 'AROMAS.MALTY', 'malty', new AromaFilter(
                (vb) => vb.getBaseAroma('malty'), AromaSelectors.aromaThreshold))
            ]
        );
    }
}

/**
 * A {@link SelectorType} for color selectors.
 */
@Injectable({
    providedIn: 'root'
})
export class ColorSelectors extends HexaselectorType {
    public constructor() {
        super(
            [ new Selector('color1', 'COLOR_CHARACTERISTICS.1C',
                null, VenueBeerFilter.CreateVenueBeerFilter(
                    (venueBeer) =>
                        venueBeer.getColor() === 1), '#FFFF01', 'black', true)
            , new Selector('color2-3', 'COLOR_CHARACTERISTICS.3C',
                null, VenueBeerFilter.CreateVenueBeerFilter(
                    (venueBeer) =>
                        venueBeer.getColor() === 2 || venueBeer.getColor() === 3), '#FE9900', 'black', true)
            , new Selector('color4-5-6', 'COLOR_CHARACTERISTICS.5C',
                null, VenueBeerFilter.CreateVenueBeerFilter(
                    (venueBeer) =>
                    venueBeer.getColor() === 4 || venueBeer.getColor() === 5 || venueBeer.getColor() === 6), '#A94214', 'white', true)
            , new Selector('color7', 'COLOR_CHARACTERISTICS.7C', null, VenueBeerFilter.CreateVenueBeerFilter(
                (venueBeer) =>
                    venueBeer.getColor() === 7), '#CC0001', 'white', true)
            , new Selector('color8', 'COLOR_CHARACTERISTICS.8C', null, VenueBeerFilter.CreateVenueBeerFilter(
                (venueBeer) =>
                    venueBeer.getColor() === 8), '#5B220E', 'white', true)
            , new Selector('color9', 'COLOR_CHARACTERISTICS.9C', null, VenueBeerFilter.CreateVenueBeerFilter(
                (venueBeer) =>
                    venueBeer.getColor() === 9), '#080808', 'white', true)
            ]
        );
    }
}
