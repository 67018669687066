import { Injectable } from '@angular/core';

/**
 * A utility service for managing cookies.
 */
@Injectable({
  providedIn: 'root'
})
export class CookieService {
  public constructor() { }

  public setCookie(name: string, val: string): void {
    const date = new Date();
    const value = val;

    // Set it expire in 90 days
    date.setTime(date.getTime() + (90 * 24 * 60 * 60 * 1000));

    // Set it in the current toplvel domain
    document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/' + ';domain=' + this.getDomainName();
  }

  public getCookie(name: string): string | null {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');

    if (parts.length === 2) {
        return parts.pop()!.split(';').shift() || null;
    }
    return null;
  }

  public deleteCookie(name: string): void {
    const date = new Date();

    // Set the expire date of the cookie to -1 days.
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set the expire time
    document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
  }

  private getDomainName(): string {
    const hostName = window.location.hostname;
    return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1);
  }
}
