import { Address } from '../../address';
import { PersistentModel } from '../persistent-model';
import { ModelTable } from '../model-table';
import { environment } from '../../../../environments/environment';
import { BHError } from '../../utilities';

/**
 * A {@link PersistentModel} representing a brewery.
 */
export class Brewery extends PersistentModel {
    private static BreweryLogoPrefix = environment.logoBaseUrl + 'brewery/logos/';

    private name: string;
    private address: Address;
    private logoUrl: string | null;

    private tenant: string | null = null;
    private tenantId: string | null = null;

    public constructor(table: ModelTable<Brewery>) {
        super(table);
        this.name = '';
        this.address = new Address();
        this.address.subscribe(() => this.makeDirty());
        this.logoUrl = null;
    }

    /**
     * Get the name of this brewery.
     *
     * @returns The name of this brewery.
     */
    public getName(): string {
        return this.name;
    }
    /**
     * Set the name of this brewery.
     *
     * @param name  The new name for this brewery.
     */
    public setName(name: string): void {
        this.name = name;
        this.makeDirty();
    }
    /**
     * Get the {@link Address} of this brewery.
     *
     * @returns The {@link Address} of this brewery.
     */
    public getAddress(): Address {
        return this.address;
    }
    /**
     * Get the full path to the logo of this brewery, or `null` if not present.
     *
     * @returns The full path to the logo of this brewery, or `null` if not present.
     */
    public getPrefixedLogoUrl(): string | null {
        if (this.logoUrl) {
            return Brewery.BreweryLogoPrefix + this.logoUrl;
        }
        return null;
    }
    /**
     * Set the relative path of the logo of this brewery.
     *
     * @param logoUrl  The new relative path to the logo of this brewery.
     */
    public setLogoUrl(logoUrl: string | null): void {
        this.logoUrl = logoUrl;
        this.makeDirty();
    }
    /**
     * Get the relative path of the logo of this brewery.
     *
     */
     public getLogoUrl(): string | null {
        return this.logoUrl;
    }
    /**
     * Get the tenant ho created of this beer.
     *
     * @returns The tenant who created this beer.
     */
     public getTenant(): string | null {
        return this.tenant;
    }
    /**
     * Set the tenant who creates this beer.
     *
     * @param tenant  The tenant who creates this beer.
     */
    public setTenant(tenant: string | null): void {
        this.tenant = tenant;
        this.makeDirty();
    }
    /**
     * Get the id of the tenant ho created of this beer.
     *
     * @returns The id of the tenant who created this beer.
     */
     public getTenantId(): string | null {
        return this.tenantId;
    }
    /**
     * Set the id of the tenant who creates this beer.
     *
     * @param tenantId  The id of the tenant who creates this beer.
     */
    public setTenantId(tenantId: string | null): void {
        this.tenantId = tenantId;
        this.makeDirty();
    }

    /**
     * Validates the instance
     * 
     * @returns array of errors, if no errors, then returns an empty array
     */
     public validate(): BHError[] {
        let result: BHError[] = [];

        if (!this.name || this.name == null || this.name.length == 0) {
            result.push(new BHError("ADD_BEER.ERROR.NAME_EMPTY", []));
        }
        if (!this.getAddress().getCountry() || this.getAddress().getCountry() == null || this.getAddress().getCountry()?.length == 0) {
            result.push(new BHError("ADD_BEER.ERROR.COUNTRY_EMPTY", []));
        }
        if (!this.getAddress().getCity() || this.getAddress().getCity() == null || this.getAddress().getCity()?.length == 0) {
            result.push(new BHError("ADD_BEER.ERROR.CITY_EMPTY", []));
        }
        return result;
    }

}
