<div class="venue-hours-wrapper">
  <div class="title">{{ translateService.instant("MENU.OPENING_TIMES") }}</div>
  <ion-grid *ngIf="openingRepresentations.length > 0" class="times-grid">
    <div *ngFor="let openingRepresentation of openingRepresentations">
      <ion-row >
        <ion-col>{{ openingRepresentation.days }}</ion-col>
        <ion-col>{{ openingRepresentation.times }}</ion-col>
      </ion-row>
    </div>
  </ion-grid>
</div>
