import { AppComponent, ScreenType } from './../app.component';
import { Listener } from './listener';

/**
 * A {@link Listener} that components can extend which already handles listening to changes in the widescreen/mobile version.
 */
export class ScreenVersionListener extends Listener {
    public screenType: ScreenType = ScreenType.desktop;

    public constructor() {
        super();
        this.subscribe(AppComponent.$screenType, (screenType) => {
            this.screenType = screenType;
            this.onScreenVersionChange(screenType);
        });
    }
    public onScreenVersionChange(screenType: ScreenType): void {
    }

    public isDeskTop() {
        return this.screenType == ScreenType.desktop;
    }
    public isMobile() {
        return this.screenType == ScreenType.mobile;
    }
    public isTabletPortrait() {
        return this.screenType == ScreenType.tablet_portrait;
    }
}
