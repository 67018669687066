import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { VenueService } from '../venue/venue.service';
import { LoggerService } from '../logger/logger.service';
import { v4 as uuidv4 } from 'uuid';

/**
 * A service responsible for tracking events.
 *
 * @TODO This service might be obsolete now we have Google Analytics. Remove?
 */
@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private userId: string | null = null;
  private startingTime: number | null = null;

  public constructor(
    private storage: Storage,
    private venueService: VenueService,
    private logger: LoggerService,
  ) {}

  public async startTracking(): Promise<void> {
    const events = (await this.storage.get('events'));
    const eventLength = (events !== undefined && events !== null ? events.length : 0);
    this.logger.debug('tracking.service.ts', 'currently tracked ' + eventLength + ' events');

    if (!this.userId || !this.startingTime) {
      // set the uuid and starting time
      this.userId = await this.getUserId();
      this.startingTime = new Date().getTime();

      // add an event listener for when the browser tab is closed
      window.addEventListener('beforeunload', () => {
        this.trackEvent('sessionEnd', {
          start: this.startingTime,
          end: new Date().getTime()
        });
      });
    }
  }

  public async trackEvent(key: string, value: any): Promise<void> {
    const event = {
      id: uuidv4(),
      userId: this.userId,
      venueId: this.venueService.getSelectedVenue()?.getId(),
      time: new Date().getTime(),
      name: key,
      value: value,
      source: window.location.pathname
    };
    this.logger.debug('tracking.service.ts', 'tracking event: ' + event.name);

    // write event to storage
    this.writeEventToStorage(event);
    // TODO log event to server: do we still ever want to do this? (we have google analytics)
  }

  private async getUserId(): Promise<string> {
    return this.storage
      .get('user-uuid')
      .then(uuid => {
        if (!uuid) {
          uuid = uuidv4();
          this.storage.set('user-uuid', uuid);
        }
        return uuid;
      })
      .catch(error => {
        this.logger.error('tracking.service.ts', 'error while fetching uuid', error);
        throw new Error('error while retrieving uuid');
      });
  }

  private async writeEventToStorage(event: any): Promise<void> {
    let events = await this.storage.get('events');
    if (!events) {
      events = [event];
    } else {
      events.push(event);
    }
    this.storage.set('events', events);
  }
}
