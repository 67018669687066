import { weekdays, Weekday, createWeekdayRecord, mapWeekdayRecord } from './weekday';
import { Serializable } from './serializable';
import { Subject, Subscription } from 'rxjs';
import { SubscribableSubject } from './subscribable';

/**
 * A class representing a time of a day, which can be subscribed to to listen for changes.
 */
export class Time extends Subject<void> implements Serializable {
    private _h: number;
    private _m: number;
    public get h(): number {
        return this._h;
    }
    public set h(value: number) {
        this._h = value;
        this.next();
    }
    public get m(): number {
        return this._m;
    }
    public set m(value: number) {
        this._m = value;
        this.next();
    }
    public constructor(h: number, m: number) {
        super();
        this._h = h;
        this._m = m;
    }
    /**
     * @inheritDoc
     */
    public toJSON(): any {
        return {
            h: this.h,
            m: this.m
        };
    }
    public toString(): string {
        return this.h.toString().padStart(2, '0') + ':' + this.m.toString().padStart(2, '0');
    }
    public equals(time: Time): boolean {
        return this.h === time.h && this.m === time.m;
    }
}
/**
 * A class representing a range of two {@link Time}s, which can be subscribed to to listen for changes.
 */
export class TimeRange extends Subject<void> implements Serializable {
    public readonly from: Time;
    public readonly to: Time;
    public constructor(h1: number, m1: number, h2: number, m2: number) {
        super();
        this.from = new Time(h1, m1);
        this.from.subscribe(this);
        this.to = new Time(h2, m2);
        this.to.subscribe(this);
    }
    public static read(data: any): TimeRange {
        return new TimeRange(data.from.h, data.from.m, data.to.h, data.to.m);
    }
    public toJSON(): any {
        return {
            from: this.from.toJSON(),
            to: this.to.toJSON()
        };
    }
    public equals(range: TimeRange): boolean {
        return range.from.equals(this.from) && range.to.equals(this.to);
    }
}
/**
 * A class representing an opening day of a venue involving a list of {@link TimeRange}s
 * and a boolean indicating whether the venue is open or not.
 * The class can be subscribed to to listen for changes.
 */
export class OpeningDay extends Subject<void> implements Serializable {
    private _isOpen = false;
    private _times: TimeRange[] = [];
    private rangeSubscriptions = new Map<TimeRange, Subscription>();
    public readonly times: readonly TimeRange[] = this._times;
    public constructor() {
        super();
    }
    public static read(data: any): OpeningDay {
        const time = new OpeningDay();
        time.setWhetherIsOpen(data.isOpen);
        data.times.forEach((range: any) => time.addTime(TimeRange.read(range)));
        return time;
    }
    public addTime(range: TimeRange): void {
        this.rangeSubscriptions.set(range, range.subscribe(this));
        this._times.push(range);
        this.next();
    }
    public removeTime(value: TimeRange | number): void {
        let index: number;
        if (typeof value === 'number') {
            index = value;
            if (index >= this.times.length || index < 0) {
                throw new Error('Index out of range');
            }
        } else {
            index = this.times.indexOf(value);
            if (index === -1) {
                throw new Error('Cannot remove time that is not present');
            }
        }
        const range = this.times[index];
        this.rangeSubscriptions.get(range)?.unsubscribe();
        this.rangeSubscriptions.delete(range);
        this._times.splice(index, 1);
        this.next();
    }
    public isOpen(): boolean {
        return this._isOpen;
    }
    public setWhetherIsOpen(value: boolean): void {
        this._isOpen = value;
        this.next();
    }

    public toJSON(): any {
        return {
            isOpen: this._isOpen,
            times: this.times.map((range) => range.toJSON())
        };
    }
    public equals(day: OpeningDay): boolean {
        return this._isOpen === day._isOpen &&
            this.times.length === day.times.length &&
            this.times.every((time, i) => time.equals(day.times[i]));
    }
}
/**
 * A class representing the opening times of a venue, involving an {@link OpeningDay} for each {@link Weekday}.
 * The class can be subscribed to to listen for changes.
 */
export class OpeningTimes extends SubscribableSubject<OpeningTimes> implements ReadonlyMap<Weekday, OpeningDay> {
    private timesRecord: Record<Weekday, OpeningDay>;
    public readonly size: number = weekdays.length;
    private timesSubscriptions: Record<Weekday, Subscription>;
    public constructor() {
        super();
        this.timesRecord = createWeekdayRecord((d) => new OpeningDay());
        this.timesSubscriptions = mapWeekdayRecord(this.timesRecord, (d) => d.subscribe(() => this.next(this)));
    }

    public reset(): void {
        weekdays.forEach((day) => {
            const time = new OpeningDay();
            this.set(day, time);
        });
    }
    public set(key: Weekday, value: OpeningDay): void {
        this.timesSubscriptions[key].unsubscribe();
        this.timesSubscriptions[key] = value.subscribe(() => this.next(this));
        this.timesRecord[key] = value;
        this.next(this);
    }
    public get(key: Weekday): OpeningDay {
        return this.timesRecord[key];
    }
    public has(key: Weekday): boolean {
        return true;
    }
    public forEach(callbackfn: (value: OpeningDay, key: Weekday, map: ReadonlyMap<Weekday, OpeningDay>) => void, thisArg?: any): void {
        weekdays.forEach((d) => callbackfn(this.timesRecord[d], d, this), thisArg);
    }

    public [Symbol.iterator](): IterableIterator<[Weekday, OpeningDay]> {
        const it = weekdays[Symbol.iterator]();
        const that = this;
        return {
            [Symbol.iterator](): IterableIterator<[Weekday, OpeningDay]> {
                return this;
            },
            next(): IteratorResult<[Weekday, OpeningDay], any> {
                const res = it.next();
                if (res.done) {
                    return {
                        done: true,
                        value: undefined
                    };
                }
                return {
                    done: false,
                    value: [res.value, that.timesRecord[res.value]]
                };
            }
        };
    }
    public entries(): IterableIterator<[Weekday, OpeningDay]> {
        return this[Symbol.iterator]();
    }
    public keys(): IterableIterator<Weekday> {
        return weekdays[Symbol.iterator]();
    }
    public values(): IterableIterator<OpeningDay> {
        const it = weekdays[Symbol.iterator]();
        const that = this;
        return {
            [Symbol.iterator](): IterableIterator<OpeningDay> {
                return this;
            },
            next(): IteratorResult<OpeningDay, any> {
                const res = it.next();
                if (res.done) {
                    return {
                        done: true,
                        value: undefined
                    };
                }
                return {
                    done: false,
                    value: that.timesRecord[res.value]
                };
            }
        };
    }
}

