import { RefdataService } from '../refdata/refdata.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalPersisterService } from './../../services/local-persister/local-persister.service';

/**
 * A service responsible for fetching raw beer and brewery data from the backend.
 */
@Injectable({
  providedIn: 'root'
})
export class BeersAndBreweriesFacadeService {
  private rawData: any = null;
  private fetchPromise: Promise<void> | null = null;

  public constructor(private http: HttpClient, private refdata: RefdataService, private localPersister: LocalPersisterService) { }

  private async fetchData(): Promise<void> {
    let lastModifiedOnServer = 0;

    let promise1 = this.http.head<any>(this.refdata.getBeersAndBreweriesLastModifiedUrl(),{ observe: "response"}).toPromise();
    let promise2 = this.localPersister.get('beer-data-last-loaded-modified');
    let promise3 = this.localPersister.get("beer-data");
    let promise4 = this.localPersister.get("brewery-data");

    let promiseAll = Promise.all([promise1, promise2, promise3, promise4]);
    await promiseAll.then((resultArray) => {
      let lastModifiedHeader: string | null = resultArray[0].headers.get("Last-Modified");
      if (lastModifiedHeader !== null) {
        lastModifiedOnServer = Date.parse(lastModifiedHeader);
      }
      let lastLoadedModifiedDate = resultArray[1];
      let beers = resultArray[2];
      let breweries = resultArray[3];

      if ((lastModifiedOnServer > lastLoadedModifiedDate) || !beers || !breweries || !lastLoadedModifiedDate) {
        // we MUST reload
        if (this.fetchPromise === null) {
          this.fetchPromise = this.http.get<any>(this.refdata.getBeersAndBreweriesUrl()).toPromise().then((result) => {
            this.rawData = result;
            this.localPersister.set('beer-data-last-loaded-modified', this.rawData.lastModified);
          }).catch((err) => console.log(err));
        }
        return this.fetchPromise;
      }
      else {
        // no changes on the server and we have the data locally available
        if (this.rawData == null) {
          this.rawData = {};
          this.rawData.beers = beers;
          this.rawData.breweries = breweries;
          this.rawData.lastModified = lastLoadedModifiedDate;
        }
        return Promise.resolve();
      }
    });
}
  public getRawBeerData(): Promise<any[]> {
    return this.fetchData().then(() => {
      return this.rawData.beers;
    });
  }
  public getRawBreweryData(): Promise<any[]> {
    return this.fetchData().then(() => {
      return this.rawData.breweries;
    });
  }
}
