import { Injectable } from '@angular/core';
import { ModelTable } from '../model-table';
import { Analysis, isAnalysisKey, isBinaryKey, BinaryKey } from './analysis';
import { Cleaner } from '../../cleaner';

/**
 * An abstract {@link ModelTable} of {@link Analysis | Analyses} responsible for reading instances.
 */
@Injectable({
  providedIn: 'root'
})
export abstract class AnalysisTable extends ModelTable<Analysis> {
  constructor() {
    super([]);
  }

  /** @throws Serialization of analyses is not supported yet */
  public serializeInstance(instance: Analysis) {
      throw new Error('Serialization of analyses is not supported yet');
  }
  /** @inheritDoc */
  public createInstance(): Analysis {
    return new Analysis(this);
  }
  /** @inheritdoc */
  public applyRawData(data: any, instance: Analysis): void {
    instance.setId(Cleaner.parseId(data.id));
    instance.setCreationTime(Cleaner.parseDate(data.creationTs));
    instance.setLastUpdate(Cleaner.parseDate(data.updateTs));

    for (const key of Object.keys(data)) {
      if (key === 'colour') {
        instance.color = data.colour;
      } else if (key === 'binaries') {
        for (const binaryKey of Object.keys(data.binaries)) {
          if (isBinaryKey(binaryKey)) {
            instance.binaries[binaryKey] = data.binaries[binaryKey];
          }
        }
      } else {
        if (isAnalysisKey(key)) {
          (instance[key] as any) = data[key];
        }
      }
    }
  }
}
