import { MapSubject } from './map-subject';

/**
 * A map from languages to string values, which has a default language and which can be subscribed to to listen for changes.
 */
export class LanguageMap extends MapSubject<string, string> {
    private getDefaultLanguage: () => string;
    public constructor(getDefaultLanguage: () => string) {
        super();
        this.getDefaultLanguage = getDefaultLanguage;
    }

    public static read(data: [string, string][], getDefaultLanguage: () => string): LanguageMap {
        const instance = new LanguageMap(getDefaultLanguage);
        data.forEach(([lang, value]) => instance.set(lang, value));
        return instance;
    }

    /**
     * Get the value in the specified language, or the {@link getDefaultValue | default value} if no value is present.
     *
     * @param language  The language to get the value in.
     * @returns The value in the specified language, or {@link getDefaultValue} if none was found.
     */
    public get(language: string): string | undefined {
        if (this.has(language)) {
            return super.get(language);
        }
        return this.getDefaultValue();
    }
    /**
     * Get the value in the default language, with the default language in brackets appended to the value.
     *
     * @returns `` `${value in default language} (${default language})` ``, or `undefined` if no value in the default language was found.
     */
    public getDefaultValue(): string | undefined {
        const defLang = this.getDefaultLanguage();
        const def = super.get(defLang);
        if (def !== undefined) {
            return `${def} (${defLang})`;
        }
        return undefined;
    }
    /**
     * Get the value in the specified language, or in the default language, or any other value if no value is present.
     *
     * @returns The value in the specified language, or in the default language,
     * or any other value if no value is present, or `undefined` if this map has no values.
     * If there was no value in the specified language, the string `` ` (${language of found value})` `` will be appended to the result.
     */
    public getAny(language: string): string | undefined {
        const value = this.get(language);
        if (value !== undefined) {
            return value;
        }
        for (const key of this.keys()) {
            return `${this.get(key)} (${key})`;
        }
        return undefined;
    }
}
