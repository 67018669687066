<div class="venue-contact-wrapper">
  <div class="text-wrapper">
    <span class="venue" *ngIf="showTitle">{{ name }}</span
    ><br *ngIf="showTitle" />
    <span class="location">{{ location }}</span>
  </div>
  <div class="contact-wrapper">
    <a *ngFor="let type of contactTypes" target="_blank"
    [href]="type.getHref(venue.getContact(type)!)"
    [title]="venue.getContact(type)">
      <ion-icon [name]="type.getIcon()" class="contact-img">
      </ion-icon>
    </a>
  </div>
</div>
