import { OnDestroy, Component } from '@angular/core';
import { Subscription, NextObserver } from 'rxjs';
import { Subscribable } from './subscribable';

/**
 * A class that components can extend which implements a convenient way of handling subsciptions.
 */
@Component({ template: '' })
export class Listener implements OnDestroy {
    private subscriptions: Subscription[] = [];

    public subscribe<T>(observable: Subscribable<T>, callbackOrObserver: (v: T) => void | NextObserver<T>): void {
        this.subscriptions.push(observable.subscribe(callbackOrObserver));
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}
