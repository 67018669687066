export class AddBeerRequest {
    constructor(
      public creationUserId: String | null,
      public latitude: Number,
      public longitude: Number,
      public name: String,
      public abv: number | null,
      public ebc: number | null,
      public ibu: number | null,
      public brewery_id: string | null | undefined,
      public tenantapikey: string | null,
      public tenantId: string | null | undefined
    ) { }
  }
